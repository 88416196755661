export default class Scope {
    static self: paper.PaperScope;

    /**
     * Control if view can be moved, dragged, etc.
     */
    static viewLocked = false;

    static getItemById = (id: string) => {
        const item = Scope.self.project.getItem({ data: { id } });
        return item;
    };

    /**
     * Returns united bounds of the given items.
     *
     * @param maxAttempt For cases where project items are not loaded yet, we can set maxAttempt
     * to a value greater than 1 to retry getting items after some `attemptInterval`.
     * @param attemptInterval
     */
    static getItemsBounds = async (ids: string[] = [], maxAttempt = 1, attemptInterval = 100): Promise<paper.Rectangle | undefined> => {
        // Add some padding to the resulting bounds to look better when items are fitted.
        const padding = 150;

        const getItems = (): paper.Item[] =>
            Scope.self.project.getItems({
                match: (item: paper.Item) => ids.includes(item.data.id),
            });

        let attempt = 1;
        let items = getItems();

        while (attempt < maxAttempt && !items.length) {
            await new Promise((resolve) => {
                setTimeout(resolve, attemptInterval);
            }); // eslint-disable-line no-await-in-loop
            items = getItems();
            attempt += 1;
        }

        const unitedBounds = items.reduce<paper.Rectangle>((box, current) => (box ? box.unite(current.bounds) : current.bounds), undefined);

        return unitedBounds ? unitedBounds.expand(padding) : undefined;
    };
}
