import { useRef, useMemo, useCallback, useEffect } from 'react';
import { Point } from 'paper';
import useFloorplanState from '../context/useFloorplanState';
import { FloorplanItem, FloorplanProps } from '../types';
import useSelectedItems from './useSelectedItems';
import Scope from '../paper-bindings/Scope';
import useFloorplanHistory from './useFloorplanHistory';
import { GRID_VIEW_LINE } from './useSnapGrid';
import { TOOLTIP } from '../Tooltip';
import { groupUtil } from '../shapeUtil';

export interface UseSelectToolReturnValue {
    keyDown: (event: paper.KeyEvent) => void;
    keyUp: (event: paper.KeyEvent) => void;
    /**
     * Check if given point in the event hits (usually overlaps) with an item on the
     * view. Use options to define the criteria for what is counted as a 'hit'.
     * Hit options: http://paperjs.org/reference/project/#hittest-points
     */
    hitTest: (event: paper.ToolEvent, options?: object) => paper.HitResult;
    /**
     * Same as hitTest, but returns multiple results as an array. If there is not match,
     * returns an empty array
     */
    hitTestAll: (event: paper.ToolEvent, options?: object) => paper.HitResult[];
    /**
     * Selects the item within bounds of which user has clicked (if any). Depending
     * on `selectBehaviour` and `mode`, it may deselect currently selected item.
     */
    trySelect: (event: paper.ToolEvent) => paper.HitResult;
    /**
     * @param point Target point to move the item to
     * @param isSnapping When moving the item freely on mouseDrag, we usually use event.point as
     * the target point. Sometimes moveItem is called in situations where we do not respect current
     * position of the cursor, and rather want to forcefully move the item to a specific point,
     * (for example to achieve snap-to-grid behaviour). In these scenarios, isSnapping should be
     * set to true, indicating we have to make up for the difference between the target point
     * and initial point the item was grabbed.
     */
    moveItem: (point: paper.Point, isSnapping?: boolean) => void;
    /**
     * Updates the new position of the item
     */
    releaseItem: () => void;
    selectItem: (toSelect: FloorplanItem['id'], multipleSelectionAllowed: boolean) => void;
    deselectItem: (itemId: FloorplanItem['id']) => void;
}

export type UseSelectTool = (
    mode: FloorplanProps['mode'],
    imageHeight: FloorplanProps['imageHeight'],
    selectBehaviour: FloorplanProps['selectBehaviour'],
) => UseSelectToolReturnValue;

const HIT_OPTIONS = {
    segments: true,
    stroke: true,
    fill: true,
    tolerance: 12,
};

const useSelectTool: UseSelectTool = (mode, imageHeight, selectBehaviour) => {
    const { activeItemIds } = useFloorplanState('withHistory');

    const changed = useRef(false);
    const group = useRef<paper.Group>();
    const point = useRef<paper.Point>(null);
    // difference between the point item was selected, and center of the item
    const grabPointDiff = useRef<paper.Point>(null);
    const updateTimeout = useRef<ReturnType<typeof setTimeout>>(null);

    const selectedItems = useSelectedItems();
    const history = useFloorplanHistory();

    useEffect(
        () => () => {
            if (group.current) groupUtil.ungroup(group.current, true);
        },
        [],
    );

    const selectItem: UseSelectToolReturnValue['selectItem'] = useCallback(
        (itemId, multipleSelectionAllowed) => {
            if (!itemId) {
                throw new Error('Cannot select an item with no id. Bad data is being passed to Floorplan component?');
            }

            if (activeItemIds.includes(itemId)) {
                return;
            }

            const shape = Scope.getItemById(itemId);
            if (!shape) {
                console.error('Tried to select a non-existing item');
                return;
            }

            if (!group.current) {
                group.current = groupUtil.create();
            }

            switch (shape.data.type) {
                case 'Path':
                case 'Circle':
                case 'Rectangle': {
                    if (multipleSelectionAllowed) {
                        selectedItems.addItem(itemId);
                        // update selection group
                        groupUtil.addChild(group.current, shape);
                    } else {
                        selectedItems.setItems([itemId]);
                        // update selection group
                        groupUtil.setChildren(group.current, [shape]);
                    }
                    break;
                }
                default:
                    break;
            }
        },
        [activeItemIds, selectedItems.addItem, selectedItems.setItems],
    );

    const deselectItem: UseSelectToolReturnValue['deselectItem'] = useCallback(
        (itemId) => {
            if (!activeItemIds.includes(itemId)) {
                return;
            }

            point.current = null;
            grabPointDiff.current = null;
            selectedItems.removeItem(itemId);

            const shape = Scope.getItemById(itemId);
            if (shape) {
                groupUtil.removeChild(group.current, shape);
            }
        },
        [activeItemIds, selectedItems.removeItem],
    );

    const keyDown: UseSelectToolReturnValue['keyDown'] = (event) => {
        if (group.current) {
            const {
                key,
                modifiers: { shift },
            } = event;
            switch (key) {
                case 'up':
                    group.current.translate(new Point(0, shift ? -10 : -1));
                    changed.current = true;
                    break;
                case 'down':
                    group.current.translate(new Point(0, shift ? 10 : 1));
                    changed.current = true;
                    break;
                case 'left':
                    group.current.translate(new Point(shift ? -10 : -1, 0));
                    changed.current = true;
                    break;
                case 'right':
                    group.current.translate(new Point(shift ? 10 : 1, 0));
                    changed.current = true;
                    break;
                default:
                    break;
            }
        }
    };

    const hitTest: UseSelectToolReturnValue['hitTest'] = useCallback((event, options) => {
        const opts = { ...HIT_OPTIONS, ...options };
        // @ts-ignore
        const result = event.tool.view._project.hitTest(event.point, opts);
        return result && result.item && !result.locked ? result : undefined;
    }, []);

    const hitTestAll: UseSelectToolReturnValue['hitTestAll'] = useCallback((event, options) => {
        const opts = { ...HIT_OPTIONS, ...options };
        // @ts-ignore
        const results = event.tool.view._project.hitTestAll(event.point, opts);
        return results.filter((result) => result.item && !result.locked);
    }, []);

    const trySelect: UseSelectToolReturnValue['trySelect'] = useCallback(
        (event) => {
            const result = hitTest(event, {
                // By default items on grid view are filtered out from hit results
                match: ({ item: possibleHit }) => possibleHit.name !== GRID_VIEW_LINE && possibleHit.name !== TOOLTIP,
            });

            if (!result) {
                return undefined;
            }

            const { item: hitItem } = result;
            const { data } = hitItem;

            // we select/deselect items under two conditions
            // 1. read-only mode and selectionBehaviour is checkbox (toggle checkbox by clicking on it)
            // 2. read-write mode while command/ctrl key is down
            const multipleSelectionAllowed =
                (mode === 'read-only' && selectBehaviour === 'checkbox') || (mode === 'read-write' && event.modifiers.command);

            if (activeItemIds.includes(data.id)) {
                if (multipleSelectionAllowed) {
                    deselectItem(data.id);
                    return result;
                }
            }

            selectItem(data.id, multipleSelectionAllowed);
            hitItem.bringToFront();
            point.current = event.point;
            grabPointDiff.current = hitItem.position.subtract(event.point);
            return result;
        },
        [activeItemIds, deselectItem, hitTest, selectItem],
    );

    const moveItem: UseSelectToolReturnValue['moveItem'] = (destination, isSnapping = false) => {
        if (group.current && point.current) {
            const target = isSnapping ? destination.subtract(grabPointDiff.current) : destination;
            group.current.translate(target.subtract(point.current));
            changed.current = true;
            point.current = target;
        }
    };

    const update = useCallback(
        (items: paper.Item[]) => {
            const updatedItems = items.map((updatedItem) => {
                const size = updatedItem.bounds.size.width;
                const { position } = updatedItem;
                const coordinates: FloorplanItem['coordinates'] = [position.x, imageHeight - position.y, size];
                return {
                    layerId: updatedItem.layer.data.id,
                    itemId: updatedItem.data.id,
                    data: { imageHeight, coordinates },
                };
            });
            history.updateItems(updatedItems);
        },
        [history],
    );

    const keyUp: UseSelectToolReturnValue['keyUp'] = useCallback(
        (event) => {
            const { key } = event;
            if (group.current && changed.current && key !== 'shift') {
                // debounce key-up update. we don't immediately record history change when user
                // presses some key multiple times. because we would end up with many small changes
                if (updateTimeout.current) {
                    clearTimeout(updateTimeout.current);
                }
                updateTimeout.current = setTimeout(() => {
                    update(group.current.children);
                    changed.current = false;
                    updateTimeout.current = null;
                }, 350);
            }
        },
        [update],
    );

    const releaseItem: UseSelectToolReturnValue['releaseItem'] = useCallback(() => {
        if (group.current && changed.current) {
            update(group.current.children);
        }
        changed.current = false;
        point.current = null;
        grabPointDiff.current = null;
    }, [update]);

    return useMemo(
        () => ({
            keyDown,
            keyUp,
            trySelect,
            moveItem,
            hitTest,
            hitTestAll,
            releaseItem,
            selectItem,
            deselectItem,
        }),
        [keyUp, trySelect, hitTest, hitTestAll, releaseItem, selectItem, deselectItem],
    );
};

export default useSelectTool;
