import React, { useEffect, memo, useRef } from 'react';
import { CanvasProps } from '../types';
import usePreviousValue from '../hooks/usePreviousValue';
import useSelectTool, { UseSelectToolReturnValue } from '../hooks/useSelectTool';
import Scope from '../paper-bindings/Scope';
import useSelectedItems from '../hooks/useSelectedItems';
import useFloorplanHistory, { getRandomId } from '../hooks/useFloorplanHistory';
import useTool from '../hooks/useTool';
import useSnapGrid, { GRID_VIEW_LINE } from '../hooks/useSnapGrid';

export interface WithDrawToolProps {
    drawToolMouseUp: (event: paper.ToolEvent) => void;
    drawToolMouseMove: (event: paper.ToolEvent) => void;
    drawToolMouseDown: (event: paper.ToolEvent) => void;
    drawToolMouseDrag: (event: paper.ToolEvent) => void;
    drawToolKeyUp: UseSelectToolReturnValue['keyUp'];
    drawToolKeyDown: UseSelectToolReturnValue['keyDown'];
}

const withDrawTool = (WrappedComponent) =>
    memo((props: CanvasProps) => {
        const shapeRef = useRef<paper.Path.Circle>();
        const tool = useTool(props.mode);
        /**
         * Indicating if view or a selected item is being dragged
         */
        const isDragging = useRef(false);
        const isMouseDownOnEmptyArea = useRef(false);
        const selectTool = useSelectTool(props.mode, props.imageHeight, props.selectBehaviour);
        const selectedItems = useSelectedItems();
        const history = useFloorplanHistory();
        const snapGrid = useSnapGrid(props);

        const removeGhostShape = () => {
            if (shapeRef.current) {
                shapeRef.current.remove();
                shapeRef.current = undefined;
            }
        };

        /**
         * 'Ghost' shape is the item that follows mouse movements. ('Pending' shape is the item
         * that has been added to the canvas, but hasn't been saved yet)
         */
        const makeGhostShape = (event: paper.ToolEvent) => {
            removeGhostShape();
            shapeRef.current = new Scope.self.Path.Circle({
                selected: true,
                center: event.point,
                fillColor: 'rgba(237, 171, 94, 0.8)',
                radius: 20,
            });
        };

        const mouseDown: WithDrawToolProps['drawToolMouseDown'] = (event) => {
            // mouse might be down because user wants to add a pending item, in which
            // case it is handled in mouseUp event. OR, they may want to select an
            // item, so we make an attempt to do so.
            if (!shapeRef.current) {
                const result = selectTool.trySelect(event);
                if (result) {
                    isDragging.current = false;
                    Scope.viewLocked = true;
                } else {
                    isMouseDownOnEmptyArea.current = true;
                }
            }
        };

        const mouseMove: WithDrawToolProps['drawToolMouseMove'] = (event) => {
            if (selectedItems.items.length) return;

            // if no item is selected when user is moving the mouse, we show a ghost
            // item that follows the cursor. However, if user hovers on an item while
            // moving the mouse, we should hide the ghost item. Giving them a chance
            // to see what's underneath and select the item.

            const hits = selectTool.hitTestAll(event, {
                // Obviously cursor is on the ghost item, so exclude it from hit results
                match: ({ item }) => (shapeRef.current ? item.id !== shapeRef.current.id : true),
                tolerance: 20,
            });

            // user is not hovering on an item, keep updating ghost items position, so it follows the mouse
            if (shapeRef.current && !hits.length) {
                shapeRef.current.position = event.point;
                return;
            }

            const overlappedWithGrid = hits.every((hit) => hit.item.name === GRID_VIEW_LINE);

            // cursor is hovering over some item
            if (shapeRef.current && hits.length) {
                // (almost) overlapped with the grid view, snap to grid
                if (overlappedWithGrid) {
                    const [nomineeHit] = hits;
                    shapeRef.current.position = nomineeHit.point;
                    return;
                }

                // hovered on another shape in the view. hide the ghost shape to allow user select the shape
                removeGhostShape();
                return;
            }

            // cursor is still hovering on a shape. Continue hiding the ghost item
            if (!overlappedWithGrid) return;

            // not hovering on a shape any more. Add the ghost shape again
            makeGhostShape(event);
        };

        const mouseUp: WithDrawToolProps['drawToolMouseUp'] = (event) => {
            if (isMouseDownOnEmptyArea.current) {
                isMouseDownOnEmptyArea.current = false;

                // just a click on an empty area
                if (!isDragging.current) {
                    // deselect items (if any)
                    if (selectedItems.items.length) selectedItems.setItems([]);
                    return;
                }

                // mouse is up because user was panning the view, so nothing has happened yet.
                // we only add a pending shape to the view if mouse is up due to clicking/tapping
                if (isDragging.current) {
                    isDragging.current = false;
                    return;
                }
            }

            if (selectedItems.items.length) {
                // releaseItem is different from deselecting. It only updates the new position of the item
                selectTool.releaseItem();
                Scope.viewLocked = false;
                return;
            }

            if (!shapeRef.current) {
                makeGhostShape(event);
                return;
            }

            const { position } = shapeRef.current;
            const size = shapeRef.current.bounds.size.width;
            history.addItem(shapeRef.current.layer, {
                type: 'Circle',
                id: getRandomId(),
                imageHeight: props.imageHeight,
                coordinates: [position.x, props.imageHeight - position.y, size],
                fillColor: shapeRef.current.fillColor.toCSS(true),
            });
        };

        const mouseDrag: WithDrawToolProps['drawToolMouseDrag'] = (event) => {
            // user's just panning the view
            if (isMouseDownOnEmptyArea.current) {
                isDragging.current = true;
                return;
            }

            // something is selected and user is draging the mouse,
            // user wants to reposition the selected item
            if (selectedItems.items.length) {
                Scope.viewLocked = true;

                if (selectedItems.items.length > 1) {
                    // ignore snap behaviour when moving multiple items at once
                    selectTool.moveItem(event.point);
                    return;
                }

                const [nomineeGridHit] = selectTool.hitTestAll(event, {
                    match: ({ item }) => item.name === GRID_VIEW_LINE,
                    tolerance: 20,
                });
                const isSnapping = Boolean(nomineeGridHit);
                const destinationPoint = isSnapping ? nomineeGridHit.point : event.point;
                selectTool.moveItem(destinationPoint, isSnapping);
                return;
            }

            // user's just panning the view
            isDragging.current = true;
        };

        const previousTool = usePreviousValue(tool.activeTool);
        useEffect(() => {
            // entered draw mode, deselect selected item (if any) and make a clean start
            if (previousTool !== 'draw' && tool.activeTool === 'draw') {
                if (selectedItems.items.length) selectedItems.setItems([]);
                snapGrid.initialise();
                return;
            }

            // exiting draw mode. de-init things
            if (tool.activeTool !== 'draw') {
                Scope.viewLocked = false;
                snapGrid.deInit();
                if (shapeRef.current) {
                    shapeRef.current.remove();
                    shapeRef.current = undefined;
                }
            }
        }, [previousTool, tool.activeTool, selectTool, props.imageHeight, props.imageWidth, snapGrid]);

        return (
            <WrappedComponent
                {...props}
                drawToolMouseUp={mouseUp}
                drawToolMouseMove={mouseMove}
                drawToolMouseDown={mouseDown}
                drawToolMouseDrag={mouseDrag}
                drawToolKeyUp={selectTool.keyUp}
                drawToolKeyDown={selectTool.keyDown}
            />
        );
    });

export default withDrawTool;
