import { FC } from 'react';
import TYPES from './types';

interface Item {
    name?: paper.Item['name'];
    locked?: paper.Item['locked'];
    data?: paper.Item['data'];
    visible?: paper.Item['visible'];
    selected?: paper.Item['selected'];
    onMouseEnter?: paper.Item['onMouseEnter'];
    onMouseLeave?: paper.Item['onMouseLeave'];
}

interface Layer extends Item {
    /** When set to true, activates the layer. */
    active?: boolean;
}

interface Raster extends Item {
    source: paper.Raster['source'];
    smoothing: paper.Raster['smoothing'];
    onLoad: paper.Raster['onLoad'];
    onError?: paper.Raster['onError'];
}

interface Tool {
    /** The name of the tool. Name is not a property of tool, rather a clue of what a tool is responsible for */
    name?: string;
    /** When set to true, activates the tool. If using multiple tools, only one can be active at a time */
    active?: boolean;
    /** The minimum distance the mouse has to drag before firing the onMouseDrag event, since the last onMouseDrag event */
    minDistance?: number;
    onMouseUp?: paper.Tool['onMouseUp'];
    onMouseDown?: paper.Tool['onMouseDown'];
    onMouseDrag?: paper.Tool['onMouseDrag'];
    onMouseMove?: paper.Tool['onMouseMove'];
    onKeyUp?: paper.Tool['onKeyUp'];
    onKeyDown?: paper.Tool['onKeyDown'];
}

export const Layer = TYPES.LAYER as unknown as FC<Layer>;
export const Raster = TYPES.RASTER as unknown as FC<Raster>;
export const Tool = TYPES.TOOL as unknown as FC<Tool>;

export const Circle = TYPES.CIRCLE as unknown as FC<Item>;
export const Rectangle = TYPES.RECTANGLE as unknown as FC<Item>;
export const Path = TYPES.PATH as unknown as FC<Item>;
export const Ellipse = TYPES.ELLIPSE as unknown as FC<Item>;
export const Group = TYPES.GROUP as unknown as FC<Item>;
export const Line = TYPES.LINE as unknown as FC<Item>;
export const PointText = TYPES.POINTTEXT as unknown as FC<Item>;

export { default as PaperView } from './PaperView';
