import React, { forwardRef } from 'react';
import { Provider } from 'react-redux';
import { WebViewFloorplanStateContext, FloorplanStateContext } from './FloorplanContext';
import { FloorPlanProviderState, store } from './store';
import StateSynchroniser from './StateSynchroniser';
import StateResetter from './StateResetter';

export interface FloorPlanProviderProps {
    children: React.ReactNode;
    variant?: 'web-view' | 'native';
    /**
     * When using a native floorplan, useSynchroniser prop must be set to true so data
     * is synchorised between web-view context and native context
     */
    useSynchroniser?: boolean;
    /**
     * Inform web-view variant of FloorplanProvider of the state change, keeping it synchronised.
     */
    onInformWebView?: <K extends keyof FloorPlanProviderState>(key: K, data: Partial<FloorPlanProviderState[K]>) => void;
}

export interface FloorplanProviderRef<K extends keyof FloorPlanProviderState = any> {
    updateState: (key: K, data: Partial<FloorPlanProviderState[K]>) => void;
    /**
     * Same as updateState, except it doesn't try to sync the other context. To avoid infinite loop
     */
    syncState: (key: K, data: Partial<FloorPlanProviderState[K]>) => void;
}

const FloorplanProvider = forwardRef<FloorplanProviderRef, FloorPlanProviderProps>((props, ref) => {
    const { variant = 'native', useSynchroniser = false } = props;

    const Context = variant === 'web-view' ? WebViewFloorplanStateContext : FloorplanStateContext;

    return (
        <Provider context={Context} store={store}>
            <StateResetter />
            {useSynchroniser ? <StateSynchroniser ref={ref} variant={variant} onInformWebView={props.onInformWebView} /> : null}
            {props.children}
        </Provider>
    );
});

export default FloorplanProvider;
