/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from 'react';
import { createSelectorHook } from 'react-redux';
import { WebViewFloorplanStateContext, FloorplanStateContext } from './FloorplanContext';
import { FloorPlanProviderState } from './store';

const useFloorplanState: <K extends keyof FloorPlanProviderState>(key: K) => FloorPlanProviderState[K] = (key) => {
    try {
        // try if web-view context is available
        const useSelector = createSelectorHook(WebViewFloorplanStateContext);
        const state = useSelector((store) => store[key as string]);
        return useMemo(() => state, [state]);
    } catch {
        try {
            // web-view context is not available, fallback to native context
            const useSelector = createSelectorHook(FloorplanStateContext);
            const state = useSelector((store) => store[key as string]);
            return useMemo(() => state, [state]);
        } catch {
            // neither contexts are available
            throw new Error('No FloorplanProvider is accessible from this part of the tree.');
        }
    }
};

export default useFloorplanState;
