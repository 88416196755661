import { useRef, useEffect, useMemo, useCallback } from 'react';
import Scope from '../paper-bindings/Scope';
import useFloorplanHistory from './useFloorplanHistory';
import { CanvasProps } from '../types';

export const GRID_VIEW_LINE = 'grid-guide';

const BASE_GUIDE_STYLES = {
    name: GRID_VIEW_LINE,
    strokeColor: 'rgba(82,139,152,0.2)',
    strokeWidth: 3,
    dashArray: [15, 2],
};

const getDistinctCoordinate = (shapes: paper.Item[], coord: 'x' | 'y'): number[] => {
    const MIN_DIFF = 10;
    return shapes.reduce((acc, shape) => {
        const coordinate = shape.position[coord];
        if (!acc.length || acc.every((item) => item - coordinate <= -MIN_DIFF || item - coordinate >= MIN_DIFF)) {
            acc.push(coordinate);
        }
        return acc;
    }, []);
};

const useSnapGrid = (props: CanvasProps) => {
    const grid = useRef<paper.Group>();
    const trackChanges = useRef(false);
    const history = useFloorplanHistory();

    const initialise = useCallback(() => {
        trackChanges.current = true;

        grid.current = new Scope.self.Group();
        const shapes = Scope.self.project.activeLayer.getItems({
            match: (item: paper.Item) => item.data.type === 'Circle',
        });

        const distincXs = getDistinctCoordinate(shapes, 'x');
        const distincYs = getDistinctCoordinate(shapes, 'y');

        const farthestUp = Math.min(...distincYs, 0);
        const farthestDown = Math.max(...distincYs, props.imageHeight);

        const farthestLeft = Math.min(...distincXs, 0);
        const farthestRight = Math.max(...distincXs, props.imageWidth);

        const verticalLines = distincXs.map(
            (itemX) =>
                new Scope.self.Path.Line({
                    ...BASE_GUIDE_STYLES,
                    segments: [
                        [itemX, farthestUp - 100],
                        [itemX, farthestDown + 100],
                    ],
                }),
        );
        const horizontalLines = distincYs.map(
            (itemY) =>
                new Scope.self.Path.Line({
                    ...BASE_GUIDE_STYLES,
                    segments: [
                        [farthestLeft - 50, itemY],
                        [farthestRight + 50, itemY],
                    ],
                }),
        );
        grid.current.addChildren([...verticalLines, ...horizontalLines]);
    }, [props.imageHeight, props.imageWidth]);

    const deInit = useCallback(() => {
        trackChanges.current = false;
        if (grid.current) {
            grid.current.remove();
        }
    }, []);

    useEffect(() => {
        if (trackChanges.current) {
            deInit();
            initialise();
        }
    }, [deInit, history.data, initialise]);

    return useMemo(
        () => ({
            initialise,
            deInit,
        }),
        [deInit, initialise],
    );
};

export default useSnapGrid;
