import { useCallback, useMemo } from 'react';
import update from 'immutability-helper';
import useFloorplanState from '../context/useFloorplanState';
import useFloorplanDispatch from '../context/useFloorplanDispatch';
import { FloorplanData, FloorplanItem, FloorplanItemChangeType, FloorplanLayer, Data, UserProvidedData, FloorplanProps } from '../types';
import { coordinateForType, toUserProvidedData } from '../hocs/withHistory';
import { initialState } from '../context/store';

const HISTORY_CHANGE: { [key: string]: FloorplanItemChangeType } = {
    ADD: 'add',
    DELETE: 'delete',
    UPDATE: 'update',
};

/**
 * Returns a pseudo-random id based on timestamp (e.g. kapv084p)
 */
export const getRandomId = (): string => Date.now().toString(36);

type FloorplanHeight = { imageHeight: FloorplanProps['imageHeight'] };

// imageHeight has to be present. See `coordinateForType` for more information.
type AddItemData = FloorplanItem & FloorplanHeight;
// Strict typing: If coordiante is passed, then imageHeight has to also be passed.
type UpdateData =
    | Partial<Omit<FloorplanItem, 'coordinates'>>
    | (Partial<FloorplanItem> & { coordinates: FloorplanItem['coordinates'] } & FloorplanHeight);

interface UseFloorplanHistoryReturnType<T extends Data = Data> {
    data: FloorplanData;
    addItem: (layer: paper.Layer, data: AddItemData) => FloorplanItem;
    /**
     * Whether it should store the changes as a new version of history or update
     * the current history
     */
    updateItems: (items: { layerId: FloorplanLayer['id']; itemId: FloorplanItem['id']; data: UpdateData }[]) => void;
    /**
     * You can update the "meta data" associated with this item (e.g. space.label, space.isBookable) as well as changeType.
     * changeType of an item is something that is managed internally by the floor plan component. The only time you
     * may need to change this value is for resetting it to undefined. For example, when changeType is 'add', you have
     * made the api request and saved the item, and now you want to reset the changeType to undefined.
     */
    updateItemData: (
        itemId: FloorplanItem['id'],
        data: Partial<FloorplanItem<T>['data']> & { id?: FloorplanItem['id']; changeType?: undefined },
    ) => void;
    removeItem: (itemId: FloorplanItem['id']) => void;
    addHistory: (givenHistory: FloorplanData, updateInPlace?: boolean) => void;
    clearHistory: () => void;
    undo: () => void;
    redo: () => void;
    canUndo: boolean;
    canRedo: boolean;
    /**
     * Categorised by changeType, lists items that have been added, updated and removed from
     * the floorplan. Categorising is useful as developers can easily find out which of the
     * CRUD api calls they need to make.
     */
    changeSet: { [key in FloorplanItemChangeType]: UserProvidedData<T>[] };
    /**
     * Number of item that have been added, updated or removed. It is a quick to know if anything
     * on the floorplan has changed in any way. For example to enable/disable 'save changes' button.
     */
    changeSetLength: number;
}

// @ts-ignore
const useFloorplanHistory: <T extends Data = Data>() => UseFloorplanHistoryReturnType<T> = () => {
    const { history, historyIndex } = useFloorplanState('withHistory');
    const setState = useFloorplanDispatch('withHistory');
    const currentHistory = history[historyIndex];

    const addHistory: UseFloorplanHistoryReturnType['addHistory'] = useCallback(
        (givenHistory, updateInPlace = true) => {
            const incrementBy = updateInPlace ? 0 : 1;
            const index = historyIndex + incrementBy;
            const nextHistory = [...history.slice(0, index), givenHistory];
            setState({ historyIndex: index, history: nextHistory });
        },
        [history, historyIndex, setState],
    );

    const addItem: UseFloorplanHistoryReturnType['addItem'] = useCallback(
        (layer, { coordinates, imageHeight, ...data }) => {
            const prevHistory = currentHistory;
            const layerIndex = prevHistory.findIndex((l) => l.id === layer.data.id);
            if (!coordinates) throw new Error('Cannot add an new item with no coordinates defined');
            const nextItem = {
                ...data,
                ...coordinateForType(coordinates, imageHeight),
                changeType: HISTORY_CHANGE.ADD,
            };
            const nextHistory = update(prevHistory, {
                [layerIndex]: { children: { $push: [nextItem] } },
            });
            addHistory(nextHistory);
            return nextItem;
        },
        [addHistory, currentHistory],
    );

    const updateItems: UseFloorplanHistoryReturnType['updateItems'] = useCallback(
        (items) => {
            const prevHistory = currentHistory;
            const nextHistory = items.reduce((acc, current) => {
                const { layerId, itemId, data: newItem } = current;
                const layerIndex = prevHistory.findIndex((l) => l.id === layerId);
                const layer = prevHistory[layerIndex];
                if (!layer) return acc;
                const itemIndex = layer.children.findIndex((i) => i.id === itemId);
                const existingItem = layer.children[itemIndex];
                if (!existingItem) return acc;
                // newItem.changeType always has priority, even if it's been explicitly set to undefined
                const changeType = 'changeType' in newItem ? newItem.changeType : existingItem.changeType || HISTORY_CHANGE.UPDATE;
                const nextItem = {
                    ...existingItem,
                    ...newItem,
                    data: { ...existingItem.data, ...newItem.data },
                    ...('coordinates' in newItem && coordinateForType(newItem.coordinates, newItem.imageHeight)),
                    changeType,
                };
                return update(acc, {
                    [layerIndex]: { children: { [itemIndex]: { $set: nextItem } } },
                });
            }, prevHistory);
            addHistory(nextHistory);
        },
        [addHistory, currentHistory],
    );

    const updateItemData: UseFloorplanHistoryReturnType['updateItemData'] = useCallback(
        (itemId, itemData) => {
            const prevHistory = currentHistory;
            const layer = prevHistory.find((l) => l.children.find((item) => item.id === itemId));
            if (!layer) return;
            const { changeType, id: newId, ...otherItemData } = itemData;
            updateItems([
                {
                    itemId,
                    layerId: layer.id,
                    data: {
                        data: otherItemData,
                        ...(newId && { id: newId }),
                        // only pass changeType is it's been explicitly given to us
                        ...('changeType' in itemData && { changeType }),
                    },
                },
            ]);
        },
        [currentHistory, updateItems],
    );

    const removeItem: UseFloorplanHistoryReturnType['removeItem'] = (itemId) => {
        const prevHistory = currentHistory;
        const layerIndex = prevHistory.findIndex((l) => l.children.find((item) => item.id === itemId));
        const layer = prevHistory[layerIndex];

        const itemIndex = layer.children.findIndex((item) => item.id === itemId);
        const item = layer.children[itemIndex];

        // we should actually remove it from history
        if (item.changeType === HISTORY_CHANGE.ADD) {
            const nextHistory = update(prevHistory, {
                [layerIndex]: { children: { $splice: [[itemIndex, 1]] } },
            });
            addHistory(nextHistory);
            return;
        }

        // otherwise just mark it as deleted
        updateItems([
            {
                layerId: layer.id,
                itemId,
                data: {
                    changeType: HISTORY_CHANGE.DELETE,
                    opacity: 0.3,
                },
            },
        ]);
    };

    const clearHistory = useCallback(() => {
        setState({
            historyIndex: initialState.withHistory.historyIndex,
            history: initialState.withHistory.history,
        });
    }, [setState]);

    const undo = useCallback(() => {
        if (historyIndex <= 0) return;
        setState({ historyIndex: historyIndex - 1 });
    }, [historyIndex, setState]);

    const redo = useCallback(() => {
        if (historyIndex >= history.length - 1) return;
        setState({ historyIndex: historyIndex + 1 });
    }, [history.length, historyIndex, setState]);

    const changeSet: UseFloorplanHistoryReturnType['changeSet'] = useMemo(() => {
        const [firstLayer] = currentHistory;
        const initialChangeSet: UseFloorplanHistoryReturnType['changeSet'] = {
            add: [],
            update: [],
            delete: [],
        };
        return firstLayer.children.reduce((acc, item) => {
            if (item.changeType === undefined) return acc;
            acc[item.changeType].push(toUserProvidedData(item, false));
            return acc;
        }, initialChangeSet);
    }, [currentHistory]);

    return {
        data: currentHistory,
        addHistory,
        addItem,
        updateItems,
        updateItemData,
        removeItem,
        clearHistory,
        undo,
        redo,
        canUndo: historyIndex > 0,
        canRedo: history.length > 1 && historyIndex + 1 < history.length,
        changeSet,
        changeSetLength: Object.values(changeSet).reduce((acc, changes) => acc + changes.length, 0),
    };
};

export default useFloorplanHistory;
