import React, { useEffect, useRef, useCallback } from 'react';
import { Point, Rectangle } from 'paper';
import useFloorplanState from '../context/useFloorplanState';
import usePreviousValue from '../hooks/usePreviousValue';
import useSelectTool from '../hooks/useSelectTool';
import { CanvasProps } from '../types';
import Scope from '../paper-bindings/Scope';
import useFloorplanDispatch from '../context/useFloorplanDispatch';
import { isValidDimension } from '../util';

export interface WithMoveToolProps {
    isImageFitted: boolean;
    /**
     * @param isResizing If false, image is being loaded for the first time, otherwise image
     * has already been loaded, and due to view size change, we're fitting the image again
     * and calculating it's new min/max zoom levels.
     */
    fitImage: (isResizing?: boolean) => void;
    moveToolMouseWheel: (event) => void;
    moveToolMouseDrag: (event: paper.MouseEvent) => void;
    moveToolMouseUp: () => void;
    moveToolMouseDown: (event: paper.ToolEvent) => void;
}

const ZOOM_FACTOR = 1.1;

const getAllowedZoom = (newZoom: number, thresholds: { min: number; max: number }) => {
    let zoom = newZoom;
    zoom = Math.max(zoom, thresholds.min);
    zoom = Math.min(zoom, thresholds.max);
    return zoom;
};

const getZoom = (viewWidth: number, viewHeight: number, imageWidth: number, imageHeight: number, variant: 'min' | 'max') => {
    // in the most zoomed out state, floorplan occupies 95% of the available screen width
    const zoomMargin = 0.95;
    const wr = (viewWidth * zoomMargin) / imageWidth;
    const hr = (viewHeight * zoomMargin) / imageHeight;
    const minZoom = wr < hr ? wr : hr;

    // no logic behind these hardcoded values. just trial and errors
    const scalar = viewWidth <= 500 ? 1.5 : 0.85;
    let maxZoom = Math.abs(Math.LOG2E * Math.log((viewWidth * scalar) / viewHeight));

    // minZoom cannot be greater than maxZoom
    if (minZoom > maxZoom) {
        maxZoom = Math.abs(Math.LOG2E * Math.log((viewWidth * 1.4) / viewHeight));
    }

    return variant === 'min' ? minZoom : maxZoom;
};

const withMoveTool = (WrappedComponent) => (props: CanvasProps) => {
    const { imageWidth, imageHeight, dimensions } = props;
    const zoomThreshold = useRef({ min: 0, max: 0 });
    const state = useFloorplanState('withMoveTool');
    const setState = useFloorplanDispatch('withMoveTool');
    const { activeItemIds } = useFloorplanState('withHistory');

    /* eslint-disable @typescript-eslint/no-use-before-define */
    type PinchRef = ReturnType<typeof getPinchEventData>;
    type PanRef = ReturnType<typeof getPanEventData>;
    /* eslint-enable @typescript-eslint/no-use-before-define */

    const tool = useSelectTool(props.mode, imageHeight, props.selectBehaviour);

    const viewRef = useRef<paper.View>();
    const pinch = useRef<PinchRef>();
    const pan = useRef<PanRef>();

    /**
     * Fit image into viewport
     */
    const fitImage: WithMoveToolProps['fitImage'] = useCallback(
        (isResizing = false) => {
            if (!isValidDimension(dimensions)) {
                setState({ imageFitted: false });
                return;
            }

            if (!isResizing) {
                setState({ imageFitted: false });
            }

            const [raster, ...otherRasters] = Scope.self.project.getItems({ class: 'Raster' });
            if (otherRasters && otherRasters.length) {
                throw new Error('Currently this component is configured to handle one raster at a time');
            }

            viewRef.current = raster.view;
            const { width, height } = dimensions;

            // calculate zoom
            const zoom = getZoom(width, height, imageWidth, imageHeight, 'min');
            zoomThreshold.current = {
                min: zoom,
                max: getZoom(width, height, imageWidth, imageHeight, 'max'),
            };

            // fit raster into original image size
            raster.fitBounds(new Rectangle(0, 0, imageWidth, imageHeight));

            // reset center, then center floorplan image on the window
            viewRef.current.center = new Point(0, 0);
            raster.view.translate(new Point(-imageWidth / 2, -imageHeight / 2));

            // reset zoom, then scale floorplan image so it fits inside the available window
            viewRef.current.zoom = 1;
            viewRef.current.scale(zoom);

            // calculate new image size
            const iw = imageWidth * zoom;
            const ih = imageHeight * zoom;
            // calculate needed translation xy
            const tx = (width - iw) / 2 / zoom;
            const ty = (height - ih) / 2 / zoom;
            // calculate center xy
            const x = state.x + tx;
            const y = state.y + ty;
            // center the image in the middle and set image loaded
            setState({ tx, ty, x, y, zoom, imageFitted: true });
        },
        [state.x, state.y, imageWidth, imageHeight, dimensions, setState],
    );

    /**
     * Get pinch zoom event data
     *
     * @param event Paper.js ToolEvent
     * @return Object representing pinch zoom event
     */
    const getPinchEventData = (e: paper.MouseEvent) => {
        const {
            // @ts-ignore
            event: {
                target: { offsetLeft, offsetTop },
                touches,
            },
        } = e;
        // touch points
        const x0 = touches[0].pageX - offsetLeft;
        const y0 = touches[0].pageY - offsetTop;
        const x1 = touches[1].pageX - offsetLeft;
        const y1 = touches[1].pageY - offsetTop;
        // center point between fingers
        const center = [(x0 + x1) / 2, (y0 + y1) / 2];
        // distance between fingers
        const distance = Math.sqrt(Math.pow(x1 - x0, 2) + Math.pow(y1 - y0, 2));
        // return object describing touch state
        return { center, distance };
    };

    /**
     * Get pinch zoom state data
     *
     * @param  prev Previous pinch zoom event data
     * @param  next Next pinch zoom event data
     * @return Next pinch zoom state
     */
    const getPinchEventState = (prev: PinchRef, next: PinchRef) => {
        const { x, y, zoom } = state;
        const view = viewRef.current;
        const center = view.viewToProject(new Point(next.center));
        const t = center.subtract(view.viewToProject(new Point(prev.center)));
        const scale = next.distance / prev.distance;
        return {
            tx: t.x,
            ty: t.y,
            sx: center.x,
            sy: center.y,
            x: x + t.x,
            y: y + t.y,
            zoom: zoom * scale,
        };
    };

    /**
     * Get pan event data
     *
     * @return Object representing pan event
     */
    const getPanEventData = (e: paper.MouseEvent) => {
        const {
            point,
            // @ts-ignore
            event: { touches, pageX, pageY },
        } = e;
        const view = viewRef.current;
        return {
            point: view.projectToView(point),
            x: touches ? touches[0].pageX : pageX,
            y: touches ? touches[0].pageY : pageY,
        };
    };

    /**
     * Get pan event state
     *
     * @param  prev Previous pan event data
     * @param  next Next pan event data
     * @return Next pan state data
     */
    const getPanEventState = ({ point }: paper.MouseEvent, prev: PanRef) => {
        const { x, y } = state;
        const view = viewRef.current;
        const t = point.subtract(view.viewToProject(prev.point));
        return {
            tx: t.x,
            ty: t.y,
            x: x + t.x,
            y: y + t.y,
        };
    };

    /**
     * @param delta scale factor.
     * @param target point in the view to zoom to. Could be cursor's position or center of view.
     * @param shouldTransform target parameter should be x,y position of the point we want to
     * zoom to. If these coordinates are relative to browser's window (e.g. clientX, clientY), we
     * should transform them to be in paperjs coordinate system. We can skip this transformation
     * if the given target is already in paperjs coordinate system (e.g. view.center)
     */
    const doZoom = useCallback(
        (delta: number, target: paper.Point, shouldTransform = true) => {
            if (!state.imageFitted) return;
            const view = viewRef.current;
            const oldZoom = view.zoom;
            const oldCenter = view.center;
            // convert mouse point to project space
            const targetPos = shouldTransform ? view.viewToProject(target) : target;
            // calculate new zoom from delta
            let newZoom = delta > 0 ? oldZoom * ZOOM_FACTOR : oldZoom / ZOOM_FACTOR;
            // make sure we adhere to min/max zoom levels
            newZoom = getAllowedZoom(newZoom, zoomThreshold.current);
            if (newZoom === oldZoom) return;
            view.zoom = newZoom;
            const zoomScale = oldZoom / newZoom;
            const centerAdjust = targetPos.subtract(oldCenter);
            const offset = targetPos.subtract(centerAdjust.multiply(zoomScale)).subtract(oldCenter);
            view.center = view.center.add(offset);
        },
        [state.imageFitted],
    );

    /**
     * Mouse wheel handler
     */
    const mouseWheel: WithMoveToolProps['moveToolMouseWheel'] = useCallback(
        (event) => {
            // React's SyntheticEvent
            if (!state.imageFitted) return;
            // stop event
            event.preventDefault();
            const { clientX, clientY } = event;
            const { offsetLeft, offsetTop } = props.offsets;
            // get wheel delta
            const delta = -event.deltaY || event.wheelDelta;
            const targetPoint = new Point(clientX - offsetLeft, clientY - offsetTop);
            doZoom(delta, targetPoint);
        },
        [props.offsets, state.imageFitted, doZoom],
    );

    /**
     * Mouse drag handler
     */
    const mouseDrag: WithMoveToolProps['moveToolMouseDrag'] = (e) => {
        if (!state.imageFitted) return;
        const {
            // @ts-ignore
            event: { touches },
        } = e;
        const view = viewRef.current;
        if (touches && touches.length === 2) {
            // pinch zoom
            if (!pinch.current) {
                pinch.current = getPinchEventData(e);
                return;
            }
            const prev = pinch.current;
            const next = getPinchEventData(e);
            // this.setState(getPinchEventState(prev, next))
            const { sx, sy, tx, ty, zoom } = getPinchEventState(prev, next);
            view.scale(zoom / state.zoom, new Point(sx, sy));
            view.translate(new Point(tx, ty));
            pinch.current = next;
        } else {
            // pan
            if (!pan.current) {
                pan.current = getPanEventData(e);
                return;
            }
            const prev = pan.current;
            const next = getPanEventData(e);
            // this.setState(getPanEventState(e, prev, next))
            // transform view manually
            const { tx, ty } = getPanEventState(e, prev);
            view.translate(new Point(tx, ty));
            pan.current = next;
        }
    };

    /**
     * Mouse up handler
     */
    const mouseUp: WithMoveToolProps['moveToolMouseUp'] = () => {
        if (!state.imageFitted) return;
        pan.current = null;
        pinch.current = null;
    };

    /**
     * Mouse down handler
     */
    const mouseDown: WithMoveToolProps['moveToolMouseDown'] = (event) => {
        if (!state.imageFitted) return;
        tool.trySelect(event);
    };

    const focusToFit = (bounds: paper.Rectangle) => {
        if (!bounds) return;
        const { width, height } = viewRef.current.viewSize;
        // center of the browser window
        const projectViewCenter = new Point(width, height).divide(2);
        // center of the browser window converted to paper.js project space coordinates
        const viewCenter = viewRef.current.viewToProject(projectViewCenter);
        if (!viewCenter) return;
        // distance between where item is and where it should be (center of view)
        const distance = viewCenter.subtract(new Point(0, 50)).subtract(bounds.center);
        // make up for the difference
        viewRef.current.translate(distance);
        // zoom to item as much as possible
        let newZoom = getZoom(width, height, bounds.width, bounds.height, 'min');
        newZoom = getAllowedZoom(newZoom, zoomThreshold.current);
        viewRef.current.zoom = newZoom;
    };

    const previousDesiredZoom = usePreviousValue(state.desiredZoom);
    useEffect(() => {
        if (previousDesiredZoom === undefined || !viewRef.current) return;
        if (previousDesiredZoom === state.desiredZoom) return;

        const delta = state.desiredZoom > previousDesiredZoom ? 1 : -1;
        doZoom(delta, viewRef.current.center, false);
    }, [doZoom, previousDesiredZoom, setState, state.desiredZoom]);

    useEffect(() => {
        if (!activeItemIds.length || !state.imageFitted || props.mode === 'read-write') return;

        const doFit = async (): Promise<void> => {
            const unitedBounds = await Scope.getItemsBounds(activeItemIds, 5);
            focusToFit(unitedBounds);
        };
        doFit();
    }, [activeItemIds, props.mode, state.imageFitted]);

    return (
        <WrappedComponent
            {...props}
            isImageFitted={state.imageFitted}
            fitImage={fitImage}
            moveToolMouseWheel={mouseWheel}
            moveToolMouseUp={mouseUp}
            moveToolMouseDrag={mouseDrag}
            moveToolMouseDown={mouseDown}
        />
    );
};

export default withMoveTool;
