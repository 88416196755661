import { useCallback, useMemo } from 'react';
import useFloorplanState from '../context/useFloorplanState';
import { Data, UserProvidedData, FloorplanItem } from '../types';
import { toUserProvidedData } from '../hocs/withHistory';
import useFloorplanDispatch from '../context/useFloorplanDispatch';

interface UseSelectedItemsReturnValue<T extends Data = Data> {
    items: UserProvidedData<T>[];
    /**
     * Sets selected items using the given array, ignoring any other
     * previously selected items. Could also be used to deselect all
     * items by passing an empty array.
     */
    setItems: (ids: FloorplanItem['id'][]) => void;
    /**
     * Adds an item to the list of selected items
     */
    addItem: (id: FloorplanItem['id']) => void;
    /**
     * Remove an item from the list of selected items
     */
    removeItem: (id: FloorplanItem['id']) => void;
}

// @ts-ignore
const useSelectedItems: <T extends Data>() => UseSelectedItemsReturnValue<T> = () => {
    const { history = [], historyIndex, activeItemIds } = useFloorplanState('withHistory');
    const setState = useFloorplanDispatch('withHistory');

    const setItems: UseSelectedItemsReturnValue['setItems'] = useCallback(
        (ids) => {
            setState({ activeItemIds: [...ids] });
        },
        [setState],
    );

    const addItem: UseSelectedItemsReturnValue['addItem'] = useCallback(
        (id) => {
            if (!activeItemIds.includes(id)) {
                setState({ activeItemIds: [...activeItemIds, id] });
            }
        },
        [activeItemIds, setState],
    );

    const removeItem: UseSelectedItemsReturnValue['removeItem'] = useCallback(
        (itemId) => {
            setState({ activeItemIds: [...activeItemIds.filter((id) => id !== itemId)] });
        },
        [activeItemIds, setState],
    );

    const currentHistory = history[historyIndex];
    return useMemo(() => {
        if (!activeItemIds.length) {
            return {
                items: [],
                setItems,
                addItem,
                removeItem,
            };
        }

        const [layer] = currentHistory;
        const items = layer.children.filter((i) => activeItemIds.includes(i.id));
        return {
            items: items.map((item) => toUserProvidedData(item, true, activeItemIds)),
            setItems,
            addItem,
            removeItem,
        };
    }, [activeItemIds, currentHistory, setItems, addItem, removeItem]);
};

export default useSelectedItems;
