import { useEffect } from 'react';
import { Point, Size, Rectangle } from 'paper';
import Scope from './paper-bindings/Scope';
import { FloorplanProps, FloorplanItem } from './types';
import { toUserProvidedData } from './hocs/withHistory';

interface TooltipProps {
    item: FloorplanItem;
    onTooltipContent: FloorplanProps['onTooltipContent'];
}

const createText = (position: [number, number], text: string, isTitle = false) =>
    new Scope.self.PointText({
        point: position,
        locked: true,
        content: text,
        fontFamily: 'Nunito',
        fontSize: isTitle ? '1.9em' : '1.4em',
        fillColor: isTitle ? '#1c1c1c' : '#737373',
        fontWeight: isTitle ? 400 : 200,
    });

/**
 * Adds a pointing down triangle at bottom center of tooltip
 */
const addPointer = (path: paper.Path, tooltipBounds: paper.Path['bounds']) => {
    const triangle = [
        tooltipBounds.bottomCenter.add(new Point(15, 0)),
        tooltipBounds.bottomCenter.add(new Point(0, 20)),
        tooltipBounds.bottomCenter.add(new Point(-15, 0)),
    ];
    path.addSegments(triangle.map((segment) => new Scope.self.Segment(segment)));
};

const applyBorderRadius = (path: paper.Path, radius: number) => {
    const segments = path.segments.slice(0);
    path.removeSegments();

    for (let i = 0, len = segments.length; i < len; i += 1) {
        const curPoint = segments[i].point;
        const nextPoint = segments[i + 1 === len ? 0 : i + 1].point;
        const prevPoint = segments[i - 1 < 0 ? segments.length - 1 : i - 1].point;
        const nextDelta = curPoint.subtract(nextPoint);
        const prevDelta = curPoint.subtract(prevPoint);

        nextDelta.length = radius;
        prevDelta.length = radius;

        path.add(new Scope.self.Segment(curPoint.subtract(prevDelta), null, prevDelta.divide(2)));
        path.add(new Scope.self.Segment(curPoint.subtract(nextDelta), nextDelta.divide(2), null));
    }
    path.closed = true;
    return path;
};

export const TOOLTIP = 'tooltip';

const createRectangle = (bounds: paper.Rectangle) => {
    const padding = new Size(70, 60);
    const rectangleBounds = bounds.expand(padding);
    const tooltip = new Scope.self.Path({
        name: TOOLTIP,
        segments: [rectangleBounds.bottomLeft, rectangleBounds.topLeft, rectangleBounds.topRight, rectangleBounds.bottomRight],
        closed: true,
        fillColor: 'white',
        shadowColor: 'rgba(0,0,0,0.2)',
        shadowBlur: 10,
        shadowRadius: 25,
        shadowOffset: 2,
    });

    tooltip.onMouseEnter = () => {
        tooltip.opacity = 0.75;
    };
    tooltip.onMouseLeave = () => {
        tooltip.opacity = 1;
    };

    applyBorderRadius(tooltip, 15);
    addPointer(tooltip, rectangleBounds);
    return tooltip;
};

const MIN_TOOLTIP_WIDTH = 350;

const Tooltip = ({ item, onTooltipContent }: TooltipProps) => {
    const { position } = item;

    useEffect(() => {
        if (typeof onTooltipContent !== 'function') return undefined;

        const activeItem = item.id;
        const content = onTooltipContent(toUserProvidedData(item, false, [activeItem]));

        const group = new Scope.self.Group({
            locked: true,
        });

        if (content.title) {
            const title = createText(position, content.title, true);
            title.translate(new Point(0, -180));
            group.addChild(title);
        }

        if (content.firstLine) {
            const firstLine = createText(position, content.firstLine);
            firstLine.translate(new Point(0, -130));
            group.addChild(firstLine);
        }

        if (content.secondLine) {
            const secondLine = createText(position, content.secondLine);
            secondLine.translate(new Point(0, -95));
            group.addChild(secondLine);
        }

        let unitedBounds = group.children.reduce<paper.Rectangle>((box, current) => (box ? box.unite(current.bounds) : current.bounds), null);
        const centerOffset = unitedBounds.width / 2;

        if (unitedBounds.width < MIN_TOOLTIP_WIDTH) {
            const offset = new Point((unitedBounds.width - MIN_TOOLTIP_WIDTH) / 2, 0);
            const size = new Size(MIN_TOOLTIP_WIDTH, unitedBounds.height);
            unitedBounds = new Rectangle(unitedBounds.point.add(offset), size);
        }

        group.translate(new Point(-(unitedBounds.width / 2), 0));
        const rectangle = createRectangle(unitedBounds);
        rectangle.translate(new Point(-centerOffset, 0));

        group.bringToFront();

        return () => {
            // selected item changed or no item is selected. remove everything anyway.
            rectangle.remove();
            group.removeChildren();
            group.remove();
        };
    }, [item, onTooltipContent, position]);

    return null;
};

export default Tooltip;
