import { useCallback, useMemo } from 'react';
import useFloorplanState from '../context/useFloorplanState';
import { CanvasProps, ActiveTool } from '../types';
import useFloorplanDispatch from '../context/useFloorplanDispatch';

export const TOOL_SHORTCUTS: { [key: string]: ActiveTool } = {
    m: 'move',
    d: 'draw',
    // p: 'pen',
};

interface UseToolReturnValue {
    activeTool: ActiveTool;
    setTool: (tool: ActiveTool) => void;
}

const useTool = (mode: CanvasProps['mode']): UseToolReturnValue => {
    const { activeTool } = useFloorplanState('useTool');
    const setState = useFloorplanDispatch('useTool');

    const setTool: UseToolReturnValue['setTool'] = useCallback(
        (newTool) => {
            if (mode === 'read-only') return;
            setState({ activeTool: newTool });
        },
        [mode, setState],
    );

    return useMemo(
        () => ({
            activeTool,
            setTool,
        }),
        [activeTool, setTool],
    );
};

export default useTool;
