import React, { PureComponent, createRef } from 'react';
import { View } from 'react-native';
// @ts-ignore
import BaseFloorplan from '@smart-buildings/smart-floorplan/BaseFloorplan';
// @ts-ignore
import FloorplanProvider, { FloorplanProviderRef } from '@smart-buildings/smart-floorplan/context/FloorplanProvider';
// @ts-ignore
import { FloorplanProps, WebViewWindow, WebViewWindowBridge } from '@smart-buildings/smart-floorplan/types';

declare global {
    interface Window extends WebViewWindow {}
}

interface AppState {
    nativeProps: Partial<FloorplanProps>;
    callbacks: {
        onItemStyle?: FloorplanProps['onItemStyle'];
        onLoadError?: FloorplanProps['onLoadError'];
        onTooltipContent?: FloorplanProps['onTooltipContent'];
    };
}

const styles = {
    root: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        height: '100%',
    },
    centered: {
        position: 'absolute',
        backgroundColor: '#000',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 24,
        fontFamily: 'Nunito',
        color: '#e2e2e2',
    },
} as const;

class App extends PureComponent<{}, AppState> {
    providerRef = createRef<FloorplanProviderRef>();

    constructor(props: {}) {
        super(props);
        this.state = {
            nativeProps: undefined,
            callbacks: {},
        };
    }

    componentDidMount = () => {
        // @ts-ignore
        if (window.ReactNativeWebView) {
            // @ts-ignore
            window.WebViewBridge = {
                onPropChange: this.handlePropSync,
                onStateChange: this.handleStateSync,
                onSetupCallback: this.handleSetupCallback,
            };

            const event = new Event('WebViewBridge');
            window.dispatchEvent(event);
        }
    };

    handlePropSync: WebViewWindowBridge['onPropChange'] = (data) => {
        this.setState({ nativeProps: data });
    };

    handleStateSync: WebViewWindowBridge['onStateChange'] = ({ key, data }) => {
        if (this.providerRef && this.providerRef.current) {
            this.providerRef.current.syncState(key, data);
        }
    };

    handleSetupCallback: WebViewWindowBridge['onSetupCallback'] = (callbackName, callback) => {
        this.setState(({ callbacks: prevCallbacks }) => ({
            callbacks: {
                ...prevCallbacks,
                [callbackName]: callback,
            },
        }));
    };

    render() {
        const { nativeProps, callbacks } = this.state;

        // @ts-ignore
        if (!window.ReactNativeWebView) {
            return (
                <View style={styles.root}>
                    <View style={styles.centered}>Smart Buildings floor plans</View>
                </View>
            );
        }

        return (
            <View style={[styles.root, nativeProps ? nativeProps.style : null]}>
                {/*
                // @ts-ignore */}
                {nativeProps && window.ReactNativeWebView && (
                    <FloorplanProvider ref={this.providerRef} useSynchroniser variant="web-view">
                        <BaseFloorplan
                            mode={nativeProps.mode}
                            data={nativeProps.data}
                            selectBehaviour={nativeProps.selectBehaviour}
                            imagePath={nativeProps.imagePath}
                            imageWidth={nativeProps.imageWidth}
                            imageHeight={nativeProps.imageHeight}
                            onItemStyle={callbacks.onItemStyle}
                            onLoadError={callbacks.onLoadError}
                            onTooltipContent={callbacks.onTooltipContent}
                        />
                    </FloorplanProvider>
                )}
            </View>
        );
    }
}

export default App;
