import React, { useRef, useEffect, forwardRef, useImperativeHandle, Fragment, MutableRefObject } from 'react';
import { FloorPlanProviderProps, FloorplanProviderRef } from './FloorplanProvider';
import useFloorplanState from './useFloorplanState';
import { bridge } from '../util';
import useFloorplanDispatch from './useFloorplanDispatch';
import { initialState, FloorPlanProviderState } from './store';
import usePreviousValue from '../hooks/usePreviousValue';

/**
 * Since we are comparing JSON.stringified version of two objects, it is
 * important that keys are sorted.
 *
 * const foo = { a: 1, b: 2 }
 * const bar = { b: 2, a: 1 }
 *
 * Both foo and bar have the same values, but JSON.stringified version of them
 * are not equal unless we sort the keys.
 */
const sortObjectByKey = (object) =>
    Object.keys(object)
        .sort()
        .reduce((acc, key) => ({ ...acc, [key]: object[key] }), {});

/**
 *
 * const object = { a: 1, b: 2 }
 * const result = filterObjectByKeys(object, ['a'])
 * --> result = { a: 1 }
 */
const filterObjectByKeys = (object, whiteList: string[]) =>
    Object.entries(object)
        .filter(([key]) => whiteList.includes(key))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

/**
 * const prev = { a: 1, b: 2, c: 3 }
 * const next = { a: 1, b: 4, c: 5 }
 *
 * const result = shallowDiff(prev, next)
 * --> result = { b: 4, c: 5 }
 *
 * const result = shallowDiff(prev, next, ['c'])
 * --> result = { c: 5 }
 *
 * @param whiteList Optional list of attributes we want to check if they have changed.
 */
const shallowDiff: <T, K extends keyof T>(prev: T, next: T, whiteList: string[]) => { [key in K]?: T[K] } = (prev, next, whiteList) => {
    const interested = filterObjectByKeys(next, whiteList as string[]);
    return Object.entries(interested).reduce((acc, [key, value]) => {
        if (prev[key] === value) return acc;
        return { ...acc, [key]: value };
    }, {});
};

interface StateSynchroniserProps {
    variant: FloorPlanProviderProps['variant'];
    onInformWebView: FloorPlanProviderProps['onInformWebView'];
}

interface SynchroniserProps<K extends keyof FloorPlanProviderState = any> extends StateSynchroniserProps {
    name: K;
    /**
     * Properties of a state that we are interested in and are needed to be synchronised
     * in order for the application to function properly.
     *
     * For example, 'activeLayerId' property of 'withHistory' state is only used internally
     * in the floor plan component and there is no need to keep it synchronised. On the other
     * hand, both floor plan (internal - webview context) and the user (external - native context)
     * are able to modify `activeItemIds` property. So that's what we need to keep in sync between
     * the contexts.
     */
    whiteList: string[];
    /**
     * When synchorising data, each context (web-view or native) maintains an object of
     * state updates received from the other counterpart.
     */
    addChangeToMap: (data: Partial<FloorPlanProviderState>) => void;
    /**
     * As a complementary part of addChangeToMage, when a context observes a state change,
     * instead of immediately informing the other counterpart, it first checks if this
     * change is actually originated from the counter parts update. Otherwise, if we don't
     * perform this check, both contexts will get stuck in a loop updating each other's context.
     */
    checkForExistingChange: (changeSet: Partial<FloorPlanProviderState>) => boolean;
}

// eslint-disable-next-line prefer-arrow-callback
const Synchroniser = forwardRef(function Comp<K extends keyof FloorPlanProviderState>(
    props: SynchroniserProps<K>,
    ref: MutableRefObject<FloorplanProviderRef<K>>,
) {
    const slicedState = useFloorplanState(props.name);
    const setState = useFloorplanDispatch(props.name);

    useImperativeHandle(ref, () => ({
        updateState: (key, data) => {
            if (props.variant !== 'native' || key !== props.name) return;
            props.addChangeToMap(filterObjectByKeys(data, props.whiteList));
            setState(data);
        },
        syncState: (key, stateChange) => {
            if (props.variant !== 'web-view' || key !== props.name) return;
            props.addChangeToMap(stateChange);
            setState(stateChange);
        },
    }));

    const previousSlicedState = usePreviousValue(slicedState);
    useEffect(() => {
        if (!previousSlicedState || JSON.stringify(slicedState) === JSON.stringify(initialState[props.name])) {
            return;
        }

        if (props.variant === 'web-view') {
            // 1. Synchroniser: detects change in web-view state
            // 2. Synchroniser: posts the state to native
            // 3. NativeFloorplan: receives posted message and calls updateState method of Synchroniser
            // 4. Synchroniser: reflects the change in native state, keeping everything synchronised.

            const changeSet = shallowDiff(previousSlicedState, slicedState, props.whiteList);
            if (!Object.keys(changeSet).length) return;

            const exists = props.checkForExistingChange(changeSet);
            if (exists) return;

            // web-view context is the source of truth. Unlike native context, we don't check for
            // 'what' has changed, rather pass the entire state to native.
            bridge.postToNative({
                stateUpdate: true,
                data: { key: props.name, data: slicedState },
            });
        }

        if (props.variant === 'native') {
            const changeSet = shallowDiff(previousSlicedState, slicedState, props.whiteList);
            if (!Object.keys(changeSet).length) return;

            const exists = props.checkForExistingChange(changeSet);
            if (exists) return;

            // @ts-ignore
            props.onInformWebView(props.name, changeSet);
        }
    }, [slicedState]);

    return null;
});

const StateSynchroniser = forwardRef<FloorplanProviderRef, StateSynchroniserProps>((props, forwardedRef) => {
    const changeMap = useRef({});
    const refs: { [key in keyof FloorPlanProviderState]?: React.MutableRefObject<FloorplanProviderRef> } = {
        useTool: useRef(),
        withHistory: useRef(),
        withMoveTool: useRef(),
    };

    const addChangeToMap: SynchroniserProps['addChangeToMap'] = (data) => {
        const changeId = Date.now().toString();
        const sorted = sortObjectByKey(data);
        changeMap.current[changeId] = JSON.stringify(sorted);
    };

    const checkForExistingChange: SynchroniserProps['checkForExistingChange'] = (changeSet) => {
        const stringifiedChange = JSON.stringify(sortObjectByKey(changeSet));
        const existing = Object.entries(changeMap.current).find(([, value]) => value === stringifiedChange);
        if (existing) {
            // mitigated an unnecessary update. Remove it from changeMap as we are done with it
            changeMap.current = Object.entries(changeMap.current).reduce((acc, [key, value]) => {
                if (key === existing[0]) return acc;
                return { ...acc, [key]: value };
            }, {});
        }

        return Boolean(existing);
    };

    const commonProps: Omit<SynchroniserProps, 'name' | 'whiteList'> = {
        addChangeToMap,
        checkForExistingChange,
        variant: props.variant,
        onInformWebView: props.onInformWebView,
    };

    useImperativeHandle(forwardedRef, () => ({
        updateState: (key, data) => {
            if (!refs[key]) return;
            refs[key].current.updateState(key, data);
        },
        syncState: (key, data) => {
            if (!refs[key]) return;
            refs[key].current.syncState(key, data);
        },
    }));

    return (
        <Fragment>
            <Synchroniser ref={refs.withHistory} name="withHistory" whiteList={['activeItemIds', 'history']} {...commonProps} />
            <Synchroniser ref={refs.withMoveTool} name="withMoveTool" whiteList={['desiredZoom']} {...commonProps} />
            <Synchroniser ref={refs.useTool} name="useTool" whiteList={['activeTool']} {...commonProps} />
        </Fragment>
    );
});

export default StateSynchroniser;
