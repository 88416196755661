import React, { memo, useEffect } from 'react';
import useFloorplanState from '../context/useFloorplanState';
import { FloorplanData, FloorplanItem, Data, FloorplanProps, CircleCoordinate, PathCoordinate, UserProvidedData } from '../types';
import { initialDataLayer } from '../util';
import usePreviousValue from '../hooks/usePreviousValue';
import useFloorplanHistory from '../hooks/useFloorplanHistory';
import { FloorPlanProviderState } from '../context/store';

type WithHistoryState = FloorPlanProviderState['withHistory'];

/**
 * Returns coordinates of an item in a format accepted by both paper.js canvas
 * (position-size or pathData) and user for database storage (array of numbers)
 *
 * In Paper.js point (0,0) is top-left corner, while in Open-Layers (0,0) is
 * bottom-left corner. We subtract all `y` values from the height of the floorplan
 * to maintain consistentcy between Paper.js and Open-Layers coordinate conventions.
 */
export const coordinateForType = (coordinates: FloorplanItem['coordinates'], imageHeight: FloorplanProps['imageHeight']) => {
    let pathData: FloorplanItem['pathData'];
    let position: FloorplanItem['position'];
    let size: FloorplanItem['size'];

    const mirrorY = (y: number) => imageHeight - y;

    if (coordinates.length === 3 && coordinates.every((item) => typeof item === 'number')) {
        const [x, yRelativeToBottom, circleSize] = coordinates as CircleCoordinate;
        size = circleSize;
        const yRelativeToTop = mirrorY(yRelativeToBottom);
        position = [x, yRelativeToTop];
    } else if (coordinates.every((item) => Array.isArray(item) && item.length === 2)) {
        pathData = (coordinates as PathCoordinate).map(([x, yRelativeToBottom]) => {
            const yRelativeToTop = mirrorY(yRelativeToBottom);
            return [x, yRelativeToTop];
        });
    } else {
        throw new Error(`Floorplan coordinates must be in format [x, y, size] or [x,y][]. Received ${JSON.stringify(coordinates)}`);
    }

    return {
        coordinates,
        // if shape type is Rectangle or Path
        ...(pathData && { pathData }),
        // if shape type is Circle
        ...(position &&
            size && {
                size,
                position,
            }),
    };
};

/**
 * Extract and return only information that is provided by the user. For example
 * storkeColor is not part of the desk information.
 *
 * @param includeChangeType Sometimes we need to pass item's changeType (to allow
 * user specify styling for an item). But most of the time changeType is redundant
 * information (when user wants to create/delete/update an item)
 */
export const toUserProvidedData = (
    item: FloorplanItem,
    includeChangeType: boolean,
    activeItemIds?: WithHistoryState['activeItemIds'],
): UserProvidedData<Data> => ({
    ...item.data,
    ...(includeChangeType && { changeType: item.changeType }),
    id: item.id,
    coordinates: item.coordinates,
    ...(activeItemIds && { selected: activeItemIds.includes(item.id) }),
});

/**
 * Transforms the data received from props into something our canvas
 * can understand (i.e. Items)
 */
const toCanvasItem = (itemData: Data, imageHeight: FloorplanProps['imageHeight']): FloorplanItem => {
    const { id: itemId, coordinates, ...otherData } = itemData;

    if (!itemId) {
        throw new Error(`Invalid data provided by user. Field 'id' is missing from item: ${JSON.stringify(itemData)}`);
    }

    if (!coordinates || !Array.isArray(coordinates)) {
        throw new Error(`Invalid data provided by user. Field 'coordinates' is not an array for item: ${JSON.stringify(itemData)}`);
    }

    return {
        id: itemId,
        data: otherData,
        ...coordinateForType(coordinates, imageHeight),
        // default stylings
        type: 'Circle',
        fillColor: 'black',
    };
};

/**
 * Transforms the data received from props into something our canvas
 * can understand (i.e. Layers)
 */
const toCanvasLayer = (data: FloorplanProps['data'] = [], imageHeight: FloorplanProps['imageHeight']): FloorplanData => [
    {
        ...initialDataLayer,
        children: data.map((item) => toCanvasItem(item, imageHeight)),
    },
];

/**
 * Allow user to override appearance of the item (shape, color, shadow, etc)
 */
const toStyledHistory = (
    layers: FloorplanData,
    activeItemIds: WithHistoryState['activeItemIds'],
    onStyleItem: FloorplanProps['onItemStyle'],
): FloorplanData =>
    layers.map((layer) => ({
        ...layer,
        children: layer.children.map((item) => {
            if (typeof onStyleItem !== 'function') return item;
            const data = toUserProvidedData(item, true, activeItemIds);
            const styles = onStyleItem(data);
            return { ...item, ...styles };
        }),
    }));

const withHistory = (WrappedComponent) =>
    memo((props: Partial<FloorplanProps>) => {
        const { addHistory } = useFloorplanHistory();
        const { history, historyIndex, activeItemIds } = useFloorplanState('withHistory');

        const previousInitialData = usePreviousValue(props.data);
        const previousSelectedItems = usePreviousValue(activeItemIds);

        const currentHistory = history[historyIndex];
        useEffect(() => {
            let canvasData: FloorplanData;
            if (previousInitialData !== props.data) {
                const dataFromProps = toCanvasLayer(props.data, props.imageHeight);
                canvasData = toStyledHistory(dataFromProps, activeItemIds, props.onItemStyle);
            } else if (previousSelectedItems !== activeItemIds) {
                canvasData = toStyledHistory(currentHistory, activeItemIds, props.onItemStyle);
            }

            if (canvasData) {
                addHistory(canvasData);
            }
        }, [previousInitialData, previousSelectedItems, props.data, props.imageHeight, props.onItemStyle, addHistory, activeItemIds, currentHistory]);

        return <WrappedComponent {...props} />;
    });

export default withHistory;
