import { RefObject } from 'react';
import WebView from 'react-native-webview';
import { WebViewToNativeMessage, FloorplanProps, FloorplanLayer, WebViewWindow, WebViewWindowBridge, ParametersOf } from './types';

export const initialDataLayer: FloorplanLayer = {
    id: '1',
    type: 'Layer',
    children: [],
};

type DimensionMeasurement = { width: number; height: number };

export const initialDimensionMeasurement: DimensionMeasurement = { width: 0, height: 0 };

/**
 * Paperjs throws exception if view width or height are zero. This is because
 * it cannot handle matrices that are non-invertable.
 * https://github.com/paperjs/paper.js/issues/1433
 *
 * Also, there is no point in displaying the floor plan when width/height
 * is 0, as nothing is going to be visible anyway.
 */
export const isValidDimension = (newMeasurement: DimensionMeasurement): boolean =>
    newMeasurement.width !== initialDimensionMeasurement.width && newMeasurement.height !== initialDimensionMeasurement.height;

declare global {
    interface Window extends WebViewWindow {}
}

type WebViewRef = RefObject<WebView>['current'];

/**
 * Returns true if path to an image has changed. We ignore query parameters
 * (such as AWS AccessKey), because the image itself hasn't changed yet
 */
export const hasImageChanged = (source: string, previousSource: string) => {
    const [newSource] = (source || '').split('?');
    const [prevSource] = (previousSource || '').split('?');
    return newSource !== prevSource;
};

/**
 * JSON.stringify removes a field which is undefined.
 *
 * Example:
 * JSON.stringify({ name: undefined, phone: 123 }) --> "{\"phone\":"123"}"
 *
 * So we replace things that are undefined with null.
 */
const stringify = (data: any, pretty = false) => {
    const replacer = (key: string, value: any) => (typeof value === 'undefined' ? null : value);
    return pretty ? JSON.stringify(data, replacer, 2) : JSON.stringify(data, replacer);
};

export const bridge = {
    /**
     * Sends logs from webview side to react-native. Useful because this way we can easily
     * see it in react-native console logs (as apposed to webview console). Once place to
     * see all the logs.
     */
    log: (data: { [key: string]: any }): void => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(stringify({ forwarded: true, data }));
        }
    },

    /**
     * Logs messages posted from react-native to web-view
     */
    printRnMessage: (message: any): void => {
        console.log(`[RN --> WebView] \n${stringify(message, true)}`);
    },

    /**
     * Sends props from react-native to web-view. Not possible to send components/children to webview because
     * they are functions and cannot be JSON.stringified. Normal functions can be injected instead.
     */
    sendPropsToWebView: (webview: WebViewRef, props: FloorplanProps): void => {
        // @ts-ignore
        bridge.postToWebView(webview, 'onPropChange', { ...props, children: undefined });
    },

    setupCallbackInWebView: (webview: WebViewRef, callbackName: string, callback: Function): void => {
        if (!webview || typeof callback !== 'function') return;
        webview.injectJavaScript(`
            if (window.WebViewBridge) {
                window.WebViewBridge.onSetupCallback('${callbackName}', ${callback.toString()}); true;
            }
        `);
    },

    postToWebView: <T extends keyof WebViewWindowBridge>(webview: WebViewRef, method: T, data: ParametersOf<WebViewWindowBridge[T]>): void => {
        if (!webview) return;
        webview.injectJavaScript(`(() => {
            if (window.WebViewBridge) {
                window.WebViewBridge.${method}(${stringify(data)});
            }
        })()`);
    },

    postToNative: (data: WebViewToNativeMessage): void => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(stringify(data));
        }
    },
};
