/* eslint-disable import/no-extraneous-dependencies */
import Reconciler from 'react-reconciler';
import {
    unstable_now as now,
    // unstable_scheduleCallback as scheduleDeferredCallback,
    // unstable_cancelCallback as cancelDeferredCallback,
} from 'scheduler';
import invariant from 'fbjs/lib/invariant';
import emptyObject from 'fbjs/lib/emptyObject';

import { Group, Item, Layer, Path, PointText, Raster, Tool } from 'paper/dist/paper-core';

import TYPES from './types';
import { hasImageChanged } from '../util';

/**
 * type NumberPoint = {
 *   x: number;
 *   y: number,
 * } | Array<number>
 */
function arePointsEqual(p1, p2) {
    if (p1 && p2) {
        // both points are array
        if (Array.isArray(p1) && Array.isArray(p2)) {
            return p1[0] === p2[0] && p1[1] === p2[1];
        }
        // both points are object
        if (!Array.isArray(p1) && !Array.isArray(p2)) {
            return p1.x === p2.x && p1.y === p2.y;
        }
        // point 1 is object, point 2 is array
        if (!Array.isArray(p1) && Array.isArray(p2)) {
            return p1.x === p2[0] && p1.y === p2[1];
        }
        // point 1 is array, point 2 is object
        if (Array.isArray(p1) && !Array.isArray(p2)) {
            return p1[0] === p2.x && p1[1] === p2.y;
        }
    } else if (!p1 && !p2) {
        // both points are null
        return true;
    }
    // some point is null
    return false;
}

function applyItemProps(instance, props, prevProps = {} as any) {
    if (props.blendMode !== prevProps.blendMode) {
        instance.blendMode = props.blendMode;
    }
    if (props.clipMask !== prevProps.clipMask) {
        instance.clipMask = props.clipMask;
    }
    if (props.opacity !== prevProps.opacity) {
        instance.opacity = props.opacity;
    }
    if (props.rotation !== prevProps.rotation) {
        instance.rotation = props.rotation;
    }
    if (props.selected !== prevProps.selected) {
        instance.selected = props.selected;
    }
    if (props.visible !== prevProps.visible) {
        instance.visible = props.visible;
    }
    if (props.locked !== prevProps.locked) {
        instance.locked = props.locked;
    }
    if (props.onMouseEnter !== prevProps.onMouseEnter) {
        instance.onMouseEnter = props.onMouseEnter;
    }
    if (props.onMouseLeave !== prevProps.onMouseLeave) {
        instance.onMouseLeave = props.onMouseLeave;
    }
}

/**
 * TODO: typescript accepted values for 'name' parameter.
 * Use values from FloorplanItemStyles as a reference
 */
function applyStyleProp(instance, props, prevProps, name) {
    const shouldApply = prevProps ? prevProps[name] !== props[name] : props[name];
    if (shouldApply) {
        instance[name] = props[name];
        // TODO: setFillColor(instance, paperProps.fillColor) // in case of gradient fill colour
    }
}

function applyStyleProps(instance, props, prevProps) {
    applyStyleProp(instance, props, prevProps, 'fillColor');
    applyStyleProp(instance, props, prevProps, 'strokeColor');
    applyStyleProp(instance, props, prevProps, 'strokeWidth');
    applyStyleProp(instance, props, prevProps, 'shadowColor');
    applyStyleProp(instance, props, prevProps, 'shadowBlur');
    applyStyleProp(instance, props, prevProps, 'shadowRadius');
    applyStyleProp(instance, props, prevProps, 'shadowOffset');
    applyStyleProp(instance, props, prevProps, 'selected');
}

function applyGroupProps(instance, props, prevProps = {} as any) {
    applyItemProps(instance, props, prevProps);
    if (!arePointsEqual(props.center, prevProps.center)) {
        instance.translate([props.center[0] - prevProps.center[0], props.center[1] - prevProps.center[1]]);
    }
    if (!arePointsEqual(props.pivot, prevProps.pivot)) {
        instance.pivot = props.pivot;
    }
    if (!arePointsEqual(props.position, prevProps.position)) {
        instance.position = props.position;
    }
    if (props.rotation !== prevProps.rotation) {
        // in case null is set
        const rotation = props.rotation ? props.rotation : 0;
        const prevRotation = prevProps.rotation ? prevProps.rotation : 0;
        instance.rotate(rotation - prevRotation);
    }
    if (props.strokeColor !== prevProps.strokeColor) {
        instance.strokeColor = props.strokeColor;
    }
    if (props.fillColor !== prevProps.fillColor) {
        instance.fillColor = props.fillColor;
    }
}

function applyLayerProps(instance, props, prevProps = {} as any) {
    applyItemProps(instance, props, prevProps);
    if (props.active !== prevProps.active && props.active === true) {
        instance.activate();
    }
    if (props.strokeColor !== prevProps.strokeColor) {
        instance.strokeColor = props.strokeColor;
        instance.children.forEach((child) => {
            if (child instanceof Path) {
                child.strokeColor = props.strokeColor;
            }
        });
    }
    if (props.fillColor !== prevProps.fillColor) {
        instance.fillColor = props.fillColor;
    }
}

function applyPathProps(instance, props, prevProps = {} as any) {
    applyItemProps(instance, props, prevProps);
    if (!arePointsEqual(props.center, prevProps.center)) {
        instance.translate([props.center[0] - prevProps.center[0], props.center[1] - prevProps.center[1]]);
    }
    if (!arePointsEqual(props.pivot, prevProps.pivot)) {
        instance.pivot = props.pivot;
        instance.position = props.position;
    }
    if (!arePointsEqual(props.position, prevProps.position)) {
        instance.position = props.position;
    }
    if (props.closed !== prevProps.closed) {
        instance.closed = props.closed;
    }
    if (props.dashArray !== prevProps.dashArray) {
        instance.dashArray = props.dashArray;
    }
    if (props.dashOffset !== prevProps.dashOffset) {
        instance.dashOffset = props.dashOffset;
    }
    if (props.fillColor !== prevProps.fillColor) {
        instance.fillColor = props.fillColor;
    }
    if (props.pathData !== prevProps.pathData) {
        instance.pathData = props.pathData;
    }
    if (!arePointsEqual(props.point, prevProps.point)) {
        instance.translate([props.point[0] - prevProps.point[0], props.point[1] - prevProps.point[1]]);
    }
    if (props.rotation !== prevProps.rotation) {
        // in case null is set
        const rotation = props.rotation ? props.rotation : 0;
        const prevRotation = prevProps.rotation ? prevProps.rotation : 0;
        instance.rotate(rotation - prevRotation);
    }
    if (props.strokeCap !== prevProps.strokeCap) {
        instance.strokeCap = props.strokeCap;
    }
    if (props.strokeColor !== prevProps.strokeColor) {
        instance.strokeColor = props.strokeColor;
    }
    if (props.strokeJoin !== prevProps.strokeJoin) {
        instance.strokeJoin = props.strokeJoin;
    }
    if (props.strokeScaling !== prevProps.strokeScaling) {
        instance.strokeScaling = props.strokeScaling;
    }
    if (props.strokeWidth !== prevProps.strokeWidth) {
        instance.strokeWidth = props.strokeWidth;
    }
}

function applyRectangleProps(instance, props, prevProps = {} as any) {
    applyPathProps(instance, props, prevProps);
    if (!arePointsEqual(props.size, prevProps.size)) {
        instance.scale(props.size[0] / prevProps.size[0], props.size[1] / prevProps.size[1]);
    }
}

function applyCircleProps(instance, props, prevProps = {} as any) {
    applyPathProps(instance, props, prevProps);
    applyStyleProps(instance, props, prevProps);
    if (props.size !== prevProps.size) {
        const radius = props.size / 2;
        const prevRadius = prevProps.size / 2;
        instance.scale(radius / prevRadius);
    }
}

function applyEllipseProps(instance, props, prevProps = {}) {
    applyRectangleProps(instance, props, prevProps);
}

function applyRasterProps(instance, props, prevProps = {} as any) {
    applyItemProps(instance, props, prevProps);
    if (hasImageChanged(props.source, prevProps.source)) {
        instance.source = props.source;
    }
    if (props.onLoad !== prevProps.onLoad) {
        instance.onLoad = props.onLoad;
    }
}

function applyPointTextProps(instance, props, prevProps = {} as any) {
    applyItemProps(instance, props, prevProps);
    if (props.content !== prevProps.content) {
        instance.content = props.content;
    }
    if (props.fillColor !== prevProps.fillColor) {
        instance.fillColor = props.fillColor;
    }
    if (props.fontFamily !== prevProps.fontFamily) {
        instance.fontFamily = props.fontFamily;
    }
    if (props.fontSize !== prevProps.fontSize) {
        instance.fontSize = props.fontSize;
    }
    if (props.fontWeight !== prevProps.fontWeight) {
        instance.fontWeight = props.fontWeight;
    }
    if (!arePointsEqual(props.point, prevProps.point)) {
        instance.translate([props.point[0] - prevProps.point[0], props.point[1] - prevProps.point[1]]);
    }
}

function applyToolProps(instance, props, prevProps = {} as any) {
    if (props.active !== prevProps.active && props.active === true) {
        instance.activate();
    }
    if (props.minDistance !== prevProps.minDistance) {
        instance.minDistance = props.minDistance;
    }
    if (props.onMouseDown !== prevProps.onMouseDown) {
        instance.onMouseDown = props.onMouseDown;
    }
    if (props.onMouseDrag !== prevProps.onMouseDrag) {
        instance.onMouseDrag = props.onMouseDrag;
    }
    if (props.onMouseMove !== prevProps.onMouseMove) {
        instance.onMouseMove = props.onMouseMove;
    }
    if (props.onMouseUp !== prevProps.onMouseUp) {
        instance.onMouseUp = props.onMouseUp;
    }
    if (props.onKeyUp !== prevProps.onKeyUp) {
        instance.onKeyUp = props.onKeyUp;
    }
    if (props.onKeyDown !== prevProps.onKeyDown) {
        instance.onKeyDown = props.onKeyDown;
    }
}

// function setFillColor(instance, fillColor) {
//     if (fillColor && typeof fillColor === 'object') { // gradient
//         instance.fillColor = {
//             gradient: { stops: fillColor },
//             origin: instance.position,
//             destination: instance.bounds.centerRight,
//         };
//         console.log(instance.position);
//     }
// }

const PaperRenderer = Reconciler({
    appendInitialChild(parentInstance, child) {
        if (typeof child === 'string') {
            // Noop for string children of Text (eg <Text>{'foo'}{'bar'}</Text>)
            invariant(false, 'Text children should already be flattened.');
        } else if (parentInstance instanceof Group && child instanceof Item) {
            child.addTo(parentInstance);
        }
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createInstance(type, props: any, paperScope) {
        const { children, ...paperProps } = props;
        let instance: any = {};

        switch (type) {
            case TYPES.TOOL:
                instance = new Tool();
                instance._applyProps = applyToolProps;
                break;
            case TYPES.CIRCLE: {
                const { size, ...otherProps } = paperProps;
                instance = new Path.Circle({ ...otherProps, radius: size / 2 });
                instance._applyProps = applyCircleProps;
                break;
            }
            case TYPES.ELLIPSE:
                instance = new Path.Ellipse(paperProps);
                instance._applyProps = applyEllipseProps;
                break;
            case TYPES.GROUP:
                instance = new Group(paperProps);
                instance._applyProps = applyGroupProps;
                break;
            case TYPES.LAYER:
                instance = new Layer(paperProps);
                instance._applyProps = applyLayerProps;
                break;
            case TYPES.LINE:
                instance = new Path.Line(paperProps);
                instance._applyProps = applyPathProps;
                break;
            case TYPES.PATH:
                instance = new Path(paperProps);
                instance._applyProps = applyPathProps;
                break;
            case TYPES.POINTTEXT:
                instance = new PointText(paperProps);
                instance._applyProps = applyPointTextProps;
                break;
            case TYPES.RECTANGLE:
                instance = new Path.Rectangle(paperProps);
                instance._applyProps = applyRectangleProps;
                break;
            case TYPES.RASTER: {
                instance = new Raster(paperProps);
                instance._applyProps = applyRasterProps;
                break;
            }
            default:
                invariant(instance, 'PaperRenderer does not support the type "%s"', type);
                break;
        }

        // apply data type
        if (!instance.data) {
            instance.data = { type };
        } else if (!instance.data.type) {
            instance.data.type = type;
        }

        invariant(instance, 'PaperRenderer does not support the type "%s"', type);

        return instance;
    },

    createTextInstance(text) {
        return text;
    },

    finalizeInitialChildren(domElement: any, type, props) {
        // If applyMatrix=true, group props should be applied after all children have been added.
        // If applyMatrix=false, only style-related props (ex. fillColor, strokeColor) should be applied.
        // TODO: add case for Layer
        switch (type) {
            case TYPES.GROUP:
                if (domElement.applyMatrix) {
                    applyGroupProps(domElement, props);
                } else {
                    // @ts-ignore
                    applyStyleProps(domElement, props);
                }
                break;
            default:
                break;
        }
        return false;
    },

    getPublicInstance(instance) {
        return instance;
    },

    prepareForCommit() {
        // Noop
        return null;
    },

    prepareUpdate() {
        return true;
    },

    resetAfterCommit() {
        // Noop
    },

    resetTextContent() {
        // Noop
    },

    // shouldDeprioritizeSubtree() {
    //     return false;
    // },

    getRootHostContext() {
        return emptyObject;
    },

    getChildHostContext() {
        return emptyObject;
    },

    isPrimaryRenderer: false,
    supportsMutation: true,
    supportsHydration: false,
    supportsPersistence: false,
    // useSyncScheduling: true,

    scheduleTimeout: setTimeout,
    cancelTimeout: clearTimeout,
    noTimeout: -1,

    now,
    // scheduleDeferredCallback,
    // cancelDeferredCallback,
    preparePortalMount() {
        // Do nothing
    },
    queueMicrotask() {
        // Do nothing
    },

    shouldSetTextContent(type, props: any) {
        return typeof props.children === 'string' || typeof props.children === 'number';
    },

    appendChild(parentInstance, child: any) {
        if (child.parentNode === parentInstance) {
            child.remove();
        }
        if (parentInstance instanceof Group && child instanceof Item) {
            child.addTo(parentInstance);
        }
    },

    appendChildToContainer(parentInstance, child: any) {
        if (child.parentNode === parentInstance) {
            child.remove();
        }
        if (parentInstance instanceof Group && child instanceof Item) {
            child.addTo(parentInstance);
        }
    },

    insertBefore(parentInstance, child, beforeChild) {
        invariant(child !== beforeChild, 'PaperRenderer: Can not insert node before itself');
        if (parentInstance instanceof Group && child instanceof Path && beforeChild instanceof Path) {
            child.insertAbove(beforeChild);
        }
    },

    insertInContainerBefore(parentInstance, child, beforeChild) {
        invariant(child !== beforeChild, 'PaperRenderer: Can not insert node before itself');
        if (parentInstance instanceof Group && child instanceof Path && beforeChild instanceof Path) {
            child.insertAbove(beforeChild);
        }
    },

    removeChild(parentInstance, child: any) {
        child.remove();
    },

    removeChildFromContainer(parentInstance, child: any) {
        child.remove();
    },

    commitTextUpdate() {
        // Noop
    },

    clearContainer() {
        return null;
    },

    commitMount() {
        // Noop
    },

    commitUpdate(instance: any, updatePayload, type, oldProps, newProps) {
        instance._applyProps(instance, newProps, oldProps);
    },
});

export default PaperRenderer;
