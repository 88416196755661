import React, { useState, useEffect, useCallback, useRef } from 'react';
import { View, StyleSheet, LayoutChangeEvent } from 'react-native';
import { FloorplanComponent } from './types';
import Canvas from './Canvas';
import { initialDimensionMeasurement } from './util';

const styles = StyleSheet.create({
    fullScreen: {
        height: '100%',
        width: '100%',
    },
    root: {
        flex: 1,
        flexDirection: 'row',
    },
    children: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
});

const BaseFloorplan: FloorplanComponent = (props) => {
    const [box, setBox] = useState(initialDimensionMeasurement);
    const [offsets, setOffset] = useState({ offsetLeft: 0, offsetTop: 0 });
    const [isWindowReady, setWindowReady] = useState(false);
    const containerRef = useRef<View>();

    const calculateContainerOffset = useCallback(() => {
        if (containerRef.current) {
            containerRef.current.measure((x, y, width, height, pageX, pageY) => {
                if (offsets.offsetLeft !== pageX || offsets.offsetTop !== pageY) {
                    setOffset({ offsetLeft: pageX, offsetTop: pageY });
                }
            });
        }
    }, [offsets, setOffset]);

    const handleLayoutChange = useCallback(
        (event: LayoutChangeEvent) => {
            const { width, height } = event.nativeEvent.layout;
            if (box.width !== width || box.height !== height) {
                setBox({ width, height });
                calculateContainerOffset();
            }
        },
        [box.width, box.height, calculateContainerOffset],
    );

    useEffect(() => {
        if (window) {
            setWindowReady(true);
        }
    }, []);

    if (!isWindowReady) {
        return null;
    }

    return (
        <View style={[styles.root, styles.fullScreen]}>
            <View ref={containerRef} style={[styles.fullScreen, props.style]} onLayout={handleLayoutChange}>
                <Canvas
                    dimensions={box}
                    offsets={offsets}
                    mode={props.mode || 'read-only'}
                    selectBehaviour={props.selectBehaviour || 'radio'}
                    data={props.data}
                    imagePath={props.imagePath}
                    imageWidth={props.imageWidth}
                    imageHeight={props.imageHeight}
                    onItemStyle={props.onItemStyle}
                    onLoadError={props.onLoadError}
                    onTooltipContent={props.onTooltipContent}
                />
            </View>

            <View pointerEvents="box-none" style={[styles.children, props.contentStyle]}>
                {props.children}
            </View>
        </View>
    );
};

export default BaseFloorplan;
