import { memo, useCallback, useEffect } from 'react';
import { CLEAR_STATE } from './store';
import { useDispatch } from './useFloorplanDispatch';

const useResetState = (): (() => void) => {
    const dispatch = useDispatch();
    const resetState = useCallback(() => dispatch({ type: CLEAR_STATE }), [dispatch]);
    return resetState;
};

/**
 * Responsible for clearing redux state when floor plan component is unmounted
 */
const StateResetter = () => {
    const resetState = useResetState();

    useEffect(
        () => () => {
            resetState();
        },
        [resetState],
    );

    return null;
};

export default memo(StateResetter);
