import React, { useRef, useCallback, useState, useEffect } from 'react';
import { View } from 'react-native';
import { compose } from 'recompose';
import { Raster, PaperView, Layer, Tool, Circle, Path } from './paper-bindings';
import LoadingIndicator from './LoadingIndicator';
import withMoveTool from './hocs/withMoveTool';
import withTools from './hocs/withTools';
import withHistory from './hocs/withHistory';
import withDrawTool from './hocs/withDrawTool';
import { CanvasPropsBase, CanvasProps } from './types';
import useFloorplanState from './context/useFloorplanState';
import useFloorplanHistory from './hooks/useFloorplanHistory';
import useTool from './hooks/useTool';
import Tooltip from './Tooltip';
import usePreviousValue from './hooks/usePreviousValue';
import { isValidDimension } from './util';

const styles = {
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
    },
    canvas: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
};

const Canvas: React.FC<CanvasProps> = ({ dimensions, isImageFitted, fitImage, onLoadError, ...props }) => {
    const { activeLayerId, activeItemIds } = useFloorplanState('withHistory');
    const [imageLoadFailed, setImageLoadFailed] = useState(false);
    const [cursor, setCursor] = useState('default');
    const { activeTool } = useTool(props.mode);
    const { data } = useFloorplanHistory();
    const viewRef = useRef<PaperView>();
    const imageLoaded = useRef(false);

    const handleLoad: CanvasProps['fitImage'] = useCallback(() => {
        imageLoaded.current = true;
        fitImage();
    }, [fitImage]);

    const handleLoadError: CanvasProps['onLoadError'] = useCallback(() => {
        setImageLoadFailed(true);
        if (onLoadError) onLoadError();
    }, [onLoadError]);

    const handleMouse = useCallback(
        (entered: boolean) => () => {
            if (activeTool === 'draw') return;
            setCursor(entered ? 'pointer' : 'default');
        },
        [activeTool],
    );

    useEffect(() => {
        if (activeTool === 'draw') setCursor('pointer');
    }, [activeTool]);

    const previousDimensions = usePreviousValue(dimensions);
    useEffect(() => {
        if (imageLoaded.current && previousDimensions !== dimensions) {
            fitImage(true);
        }
    }, [fitImage, previousDimensions, dimensions]);

    if (!isValidDimension(dimensions)) {
        return (
            <View style={styles.root}>
                <LoadingIndicator failed={imageLoadFailed} isLoading={!isImageFitted} />
            </View>
        );
    }

    return (
        <View style={styles.root}>
            <LoadingIndicator failed={imageLoadFailed} isLoading={!isImageFitted} />
            <PaperView
                ref={viewRef}
                width={dimensions.width}
                height={dimensions.height}
                style={{ ...styles.canvas, cursor }}
                onWheel={props.moveToolMouseWheel}
                onMouseDrag={props.moveToolMouseDrag}
                onMouseUp={props.moveToolMouseUp}
            >
                {data.map(({ id: layerId, type, children }) => (
                    <Layer key={layerId} data={{ id: layerId, type }} visible={isImageFitted} active={layerId === activeLayerId}>
                        <Raster locked smoothing source={props.imagePath} onLoad={handleLoad} onError={handleLoadError} />

                        {children.map((item) =>
                            !activeItemIds.includes(item.id) ? null : <Tooltip key={item.id} item={item} onTooltipContent={props.onTooltipContent} />,
                        )}

                        {children.map(({ id: itemId, type: itemType, pathData, disable, ...other }) => {
                            let Item = Circle;
                            // Rectangles are just closed paths
                            if (itemType === 'Path' || itemType === 'Rectangle') Item = Path;
                            if (itemType === 'Layer') Item = Layer;
                            return (
                                <Item
                                    key={itemId}
                                    {...other}
                                    locked={disable}
                                    onMouseEnter={handleMouse(true)}
                                    onMouseLeave={handleMouse(false)}
                                    data={{ id: itemId, type: Item }}
                                    selected={props.mode === 'read-write' && activeItemIds.includes(itemId)}
                                />
                            );
                        })}
                    </Layer>
                ))}

                <Tool name="move" active={activeTool === 'move'} onMouseDown={props.moveToolMouseDown} />

                <Tool
                    name="draw"
                    minDistance={3}
                    active={activeTool === 'draw'}
                    onMouseUp={props.drawToolMouseUp}
                    onMouseDown={props.drawToolMouseDown}
                    onMouseMove={props.drawToolMouseMove}
                    onMouseDrag={props.drawToolMouseDrag}
                    onKeyUp={props.drawToolKeyUp}
                    onKeyDown={props.drawToolKeyDown}
                />
            </PaperView>
        </View>
    );
};

export default compose(withTools, withHistory, withMoveTool, withDrawTool)(Canvas) as unknown as React.FC<CanvasPropsBase>;
