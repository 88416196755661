import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { initialDataLayer } from '../util';
import { ActiveTool, FloorplanData, FloorplanLayer, FloorplanItem } from '../types';

interface WithHistoryState {
    historyIndex: number;
    history: FloorplanData[];
    activeLayerId?: FloorplanLayer['id'];
    activeItemIds?: FloorplanItem['id'][];
}

interface WithMoveToolState {
    /** Scale center x */
    sx: number;
    /** Scale center y */
    sy: number;
    /** Translate x */
    tx: number;
    /** Translate y */
    ty: number;
    x: number;
    y: number;
    zoom: number;
    /** Floor plan image loaded AND fitted or not */
    imageFitted: boolean;
    /**
     * Requested zoom level by user. Exact value does not matter. We zoom in,
     * if new value is bigger than old value, zoom out otherwise.
     */
    desiredZoom?: number;
}

interface UseToolState {
    activeTool: ActiveTool;
}

export interface FloorPlanProviderState {
    withHistory: WithHistoryState;
    withMoveTool: WithMoveToolState;
    useTool: UseToolState;
}

export const initialState: FloorPlanProviderState = {
    withHistory: {
        historyIndex: 0,
        history: [[initialDataLayer]],
        activeLayerId: initialDataLayer.id,
        activeItemIds: [],
    },
    withMoveTool: {
        sx: 0,
        sy: 0,
        tx: 0,
        ty: 0,
        x: 0,
        y: 0,
        zoom: 1,
        desiredZoom: 0,
        imageFitted: false,
    },
    useTool: {
        activeTool: 'move',
    },
};

type ReducerAction = { type: string; data?: any };

const reducer: <K extends keyof FloorPlanProviderState>(slice: K, firstState: Partial<FloorPlanProviderState[K]>) => any =
    (slice, firstState) =>
    (state = firstState, action: ReducerAction = { type: '' }) => {
        if (!action.type) {
            throw new Error(`No action.type is specified. One of the following are allowed: ${Object.keys(initialState)}`);
        }

        if (slice !== action.type) {
            return state;
        }

        return {
            ...state,
            ...action.data,
        };
    };

export const CLEAR_STATE = 'CLEAR_STATE';

const appReducer = combineReducers({
    withHistory: reducer('withHistory', initialState.withHistory),
    withMoveTool: reducer('withMoveTool', initialState.withMoveTool),
    useTool: reducer('useTool', initialState.useTool),
});

const rootReducer = (state, action: ReducerAction) => {
    if (action.type === CLEAR_STATE) {
        state = undefined;
    }

    return appReducer(state, action);
};

export const store = createStore(rootReducer, composeWithDevTools());
