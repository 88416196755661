import React, { Component, createRef } from 'react';
import { PaperScope, Size } from 'paper/dist/paper-core';
import { FiberRoot } from 'react-reconciler';
import PaperRenderer from './PaperRenderer';
import Scope from './Scope';
import { isValidDimension } from '../util';

const noOp = () => {};
const eventOptions = { passive: false };

interface PaperViewProps {
    children?: React.ReactNode;
    onMouseDrag: (event: paper.MouseEvent) => void;
    onMouseUp: (event: paper.MouseEvent) => void;
    onWheel: (event: React.WheelEvent<HTMLCanvasElement>) => void;
    width: number;
    height: number;
    settings?: object;
    style?: object;
}

class PaperView extends Component<PaperViewProps> {
    canvas: { current: HTMLCanvasElement | null };

    mountNode: FiberRoot;

    constructor(props: PaperViewProps) {
        super(props);
        this.canvas = createRef();
    }

    componentDidMount() {
        const { children, width, height, settings } = this.props;
        if (!isValidDimension({ width, height })) return;

        Scope.self = new PaperScope();
        Scope.self.setup(this.canvas.current);

        // @ts-ignore
        this.canvas.current.addEventListener('wheel', this.props.onWheel, eventOptions);

        Scope.self.view.onMouseDrag = (event: paper.MouseEvent) => {
            if (Scope.viewLocked) return;
            this.props.onMouseDrag(event);
        };

        Scope.self.view.onMouseUp = (event: paper.MouseEvent) => {
            if (Scope.viewLocked) return;
            this.props.onMouseUp(event);
        };

        if (settings) {
            for (const key of Object.keys(settings)) {
                Scope.self.settings[key] = settings[key];
            }
        }

        Scope.self.view.viewSize = new Size(width, height);

        // @ts-ignore
        this.mountNode = PaperRenderer.createContainer(Scope.self);
        PaperRenderer.updateContainer(children, this.mountNode, this, noOp);
    }

    componentDidUpdate(prevProps: PaperViewProps) {
        const { children, width, height } = this.props;
        if (!isValidDimension({ width, height })) return;

        const { view } = Scope.self;

        PaperRenderer.updateContainer(children, this.mountNode, this, noOp);

        if (width !== prevProps.width || height !== prevProps.height) {
            try {
                const prevCenter = view.center;
                view.viewSize = new Size(width, height);
                view.translate(view.center.subtract(prevCenter));
            } catch {
                // do nothing
            }
        }

        if (this.props.onWheel !== prevProps.onWheel) {
            // @ts-ignore
            this.canvas.current.removeEventListener('wheel', prevProps.onWheel, eventOptions);
            // @ts-ignore
            this.canvas.current.addEventListener('wheel', this.props.onWheel, eventOptions);
        }
    }

    componentWillUnmount() {
        // @ts-ignore
        this.canvas.current.removeEventListener('wheel', this.props.onWheel, eventOptions);
        PaperRenderer.updateContainer(null, this.mountNode, this, noOp);
    }

    render() {
        return <canvas ref={this.canvas} style={this.props.style} />;
    }
}

PaperRenderer.injectIntoDevTools({
    findFiberByHostInstance: () => null,
    bundleType: process.env.NODE_ENV === 'production' ? 0 : 1,
    rendererPackageName: '@smart-buildings/smart-floorplan',
    version: '2.0.0',
});

export default PaperView;
