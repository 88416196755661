/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from 'react';
import { createDispatchHook } from 'react-redux';
import { WebViewFloorplanStateContext, FloorplanStateContext } from './FloorplanContext';
import { FloorPlanProviderState } from './store';

const useNativeDispatch = createDispatchHook(FloorplanStateContext);
const useWebviewDispatch = createDispatchHook(WebViewFloorplanStateContext);

export const useDispatch = () => {
    let dispatch;
    try {
        // try if web-view context is available
        dispatch = useWebviewDispatch();
    } catch {
        try {
            // web-view context is not available, fallback to native context
            dispatch = useNativeDispatch();
        } catch {
            // do nothing
        }
    }

    if (!dispatch) {
        // neither contexts are available
        throw new Error('No FloorplanProvider is accessible from this part of the tree.');
    }

    return dispatch;
};

const useFloorplanDispatch: <K extends keyof FloorPlanProviderState>(slice: K) => (newState: Partial<FloorPlanProviderState[K]>) => void = (
    slice,
) => {
    const dispatch = useDispatch();
    const enhancedDispatch = useCallback((data) => dispatch({ type: slice, data }), [dispatch, slice]);

    return enhancedDispatch;
};

export default useFloorplanDispatch;
