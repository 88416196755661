import Scope from './paper-bindings/Scope';

// eslint-disable-next-line import/prefer-default-export
export const groupUtil = {
    /** Creates and returns a group */
    create: (): paper.Group => new Scope.self.Group(),
    /**
     * Adds the given item as a child of group
     */
    addChild: (group: paper.Group, item: paper.Item): void => {
        group.addChild(item);
    },
    /**
     * Exclude item from children list. Without destroying it.
     */
    removeChild: (group: paper.Group, item: paper.Item) => {
        group.parent.insertChildren(group.index, group.removeChildren(item.index, item.index + 1));
    },
    /**
     * Adds the given list of items as children of the group, overriding any items
     * that were previously a member (child) of the group.
     */
    setChildren: (group: paper.Group, items: paper.Item[]): void => {
        groupUtil.ungroup(group);
        group.addChildren(items);
    },
    /**
     * Excludes are children from the group, making it an empty group. Optionally
     * destroys the group after removing all of its children.
     */
    ungroup: (group: paper.Group, removeAfterUngroup = false): void => {
        group.parent.insertChildren(group.index, group.removeChildren());
        if (removeAfterUngroup) {
            group.remove();
        }
    },
};
