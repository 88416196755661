import React, { useEffect, useState } from 'react';

const styles = {
    centred: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    errorMessage: {
        fontSize: 24,
        fontFamily: 'Nunito',
    },
} as const;

function useDelay<T>(value: T, delay: number): T | undefined {
    const [state, setState] = useState<T>();

    useEffect(() => {
        const handler = setTimeout(() => {
            setState(value);
        }, delay);

        return (): void => {
            clearTimeout(handler);
        };
    }, [delay, value]);

    return state;
}

interface LoadingIndicatorProps {
    failed: boolean;
    isLoading: boolean;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = (props) => {
    // Avoid flickering loading indicator by waiting for 300ms
    const delayedIsLoading = useDelay(props.isLoading, 300);

    if (props.failed) {
        return (
            <div style={styles.centred}>
                <span style={styles.errorMessage}>Failed to load floor plan image</span>
            </div>
        );
    }

    if (!props.isLoading) {
        return null;
    }

    if (delayedIsLoading === true) {
        return (
            <div style={styles.centred}>
                <svg width="50" height="50" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" stroke="#000">
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(1 1)" strokeWidth="2">
                            <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform
                                    attributeName="transform"
                                    repeatCount="indefinite"
                                    type="rotate"
                                    from="0 18 18"
                                    to="360 18 18"
                                    dur="1s"
                                />
                            </path>
                        </g>
                    </g>
                </svg>
            </div>
        );
    }

    return null;
};

export default LoadingIndicator;
