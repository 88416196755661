import React, { useEffect, useCallback, memo } from 'react';
import { CanvasProps } from '../types';
import useTool, { TOOL_SHORTCUTS } from '../hooks/useTool';

const withTools = (WrappedComponent) =>
    memo((props: CanvasProps) => {
        const tool = useTool(props.mode);

        const keyDown = useCallback(
            (event: KeyboardEvent) => {
                const { key, target } = event;
                // @ts-ignore
                const notTyping = target.nodeName && target.nodeName.toLowerCase() !== 'input';
                const newTool = notTyping && TOOL_SHORTCUTS[key];
                if (newTool && tool.activeTool !== newTool) {
                    tool.setTool(newTool);
                }
            },
            [tool],
        );

        useEffect(() => {
            // Keyboard shortcuts are used to switch tools. On read-only mode people
            // can only view things. Therefore shouldn't be able to change the default
            // tool (i.e. move tool)
            if (props.mode === 'read-only') return undefined;

            document.addEventListener('keydown', keyDown);
            return () => {
                document.removeEventListener('keydown', keyDown);
            };
        }, [keyDown, props.mode]);

        return <WrappedComponent {...props} />;
    });

export default withTools;
