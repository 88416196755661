export default {
    CIRCLE: 'Circle',
    ELLIPSE: 'Ellipse',
    GROUP: 'Group',
    LAYER: 'Layer',
    LINE: 'Line',
    PATH: 'Path',
    POINTTEXT: 'PointText',
    RASTER: 'Raster',
    RECTANGLE: 'Rectangle',
    TOOL: 'Tool',
};
